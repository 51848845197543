import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import { membershipsColumns } from './constants';
import { getMembershipsTableRow as getTableRow } from './helpers';

export const ClientDetailsMembershipsList = ({ clientSlug }) => {
  const options = {
    isSearchExculded: true,
    filters: { clientSlug, sortColumn: 'dateTo' },
  };
  const { pageSWRs, pages, ...tableLayoutProps } = hooks.useTableLayoutData('memberships', membershipsColumns, getTableRow, options);

  return (
    <C.TableLayout
      isInline
      isLoading={TH.checkIsTableLoading(pageSWRs)}
      columns={membershipsColumns}
      pages={pages}
      emptyListSubtitle="Brak zakupionych karnetów"
      {...tableLayoutProps}
    />
  );
};
