import React from 'react';
import { styled } from 'baseui';

import { ReactComponent as IconSvg } from './icon.svg';

const Wrapper = styled('div', ({ $theme, $WrapperOverrides }) => ({
  display: 'flex',
  padding: '0.5rem 1.25rem',
  alignItems: 'center',
  marginTop: $WrapperOverrides.marginTop || '1rem',
  marginBottom: $WrapperOverrides.marginBottom || '1.25rem',
  marginRight: $WrapperOverrides.marginRight || 0,
  marginLeft: $WrapperOverrides.marginLeft || 0,
  position: 'relative',
  overflow: 'hidden',
  ...$theme.borderRadius.sm,
  ...$WrapperOverrides,

  ':before': {
    content: "''",
    backgroundColor: $theme.colors.error,
    opacity: 0.1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
}));

const Message = styled('p', ({ $theme }) => ({
  margin: '0 0 0 1rem',
  color: $theme.colors.error,
  ...$theme.typography.font100,
}));

export const ErrorMessage = ({ error, $WrapperOverrides }) => (
  <Wrapper $WrapperOverrides={$WrapperOverrides || {}}>
    <IconSvg />
    <Message>{error}</Message>
  </Wrapper>
);
