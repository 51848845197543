import React from 'react';
import { styled } from 'baseui';

import { ReactComponent as NoRecordsSvg } from './no-records.svg';

const EmptyListWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '21rem',
});

const Title = styled('p', ({ $theme }) => ({
  ...$theme.typography.font360,
  color: $theme.colors.text,
  marginTop: '1rem',
  marginBottom: '0.25rem',
}));

const Subtitle = styled('p', ({ $theme }) => ({
  ...$theme.typography.font260,
  color: $theme.colors.text300,
  margin: 0,
}));

export const TableEmptyListIndicator = ({ title, subtitle }) => (
  <EmptyListWrapper>
    <NoRecordsSvg />
    <Title>{typeof title === 'string' ? title : 'Oooops!'}</Title>
    <Subtitle>{typeof subtitle === 'string' ? subtitle : 'Brak wyników'}</Subtitle>
  </EmptyListWrapper>
);
