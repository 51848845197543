import React from 'react';
import { Modal as BUIModal } from 'baseui/modal';

import * as C from './components';

export const MultistepModal = ({ isOpen, onClose, title, minHeight, maxStep, children }) => (
  <BUIModal
    isOpen={isOpen}
    onClose={onClose}
    closeable={false}
    overrides={{
      Dialog: {
        style: ({ $theme }) => ({
          ...$theme.borderRadius.lg,
          overflow: 'hidden',
          width: 'auto',
          minHeight,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          paddingBottom: '6.375rem',
        }),
      },
      DialogContainer: { style: ({ $theme }) => ({ backdropFilter: 'blur(2.5px)' }) },
      Close: {
        style: ({ $theme }) => {
          return {
            outline: `${$theme.colors.warning200} solid`,
            backgroundColor: $theme.colors.warning200,
          };
        },
      },
    }}
  >
    <C.Header>
      <C.Title>{title}</C.Title>
      <C.CloseButton onClick={onClose} />
    </C.Header>
    <C.Body>{children}</C.Body>
  </BUIModal>
);
