import React from 'react';

import * as C from 'components';
import { BackButton } from './BackButton';

export const ResetPasswordForm = ({ isDesktop }) => (
  <C.Container>
    <BackButton isDesktop={isDesktop} />
    <C.Title style={{ marginBottom: isDesktop ? '2rem' : '1.5rem' }}>
      Nie pamiętasz hasła?
      <br />
      To nie problem
    </C.Title>
    <C.Input placeholder="Podaj swój e-mail" type="email" />
    <C.Button onClick={() => alert('click')}>Zresetuj hasło</C.Button>
  </C.Container>
);
