export const cardsColumns = [
  {
    title: 'Numer karty',
    direction: null,
    width: '140px',
  },
  {
    title: 'Status',
    direction: null,
    width: '160px',
  },
  {
    title: 'Data ostatniej edycji',
    direction: 'ASC',
    width: '200px',
  },
  {
    title: '',
    width: '68px',
  },
];
