import React from 'react';
import { Button as BUIButton } from 'baseui/button';

import { withWidth } from 'hoc';

export const Button = withWidth(({ children, width, ...props }) => (
  <BUIButton
    {...props}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => {
          return {
            ...$theme.typography.font360,
            display: 'block',
            width: '100%',
            height: width < $theme.breakpoints.large ? '3.75rem' : '3.125rem',
            boxSizing: 'border-box',
            position: 'relative',
            ...$theme.borderRadius.sm,
          };
        },
      },
    }}
  >
    {children}
  </BUIButton>
));
