import React from 'react';

import { useAuthContext } from 'context/AuthContext';
import * as C from 'components';

import * as OC from './components';
import * as H from './helpers';

export const Offers = () => {
  const { me } = useAuthContext();
  const isEmployee = me.role === 'employee';

  const offersStore = H.useOffersState();

  const columns = H.getColumns(isEmployee);

  const getTableRow = (item) => H.getTableRow(item, isEmployee, offersStore.openEditModal, H.toggleOfferAction);

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.ContentBox isLoading={false}>
          <C.ContentSection type="secondary" title="Aktywne promocje">
            <OC.ActiveOffersTableLayout columns={columns} getTableRow={getTableRow} />
          </C.ContentSection>
          <C.ContentSection type="secondary" title="Nieaktywne promocje">
            <OC.InactiveOffersTableLayout columns={columns} getTableRow={getTableRow} />
          </C.ContentSection>
        </C.ContentBox>
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};
