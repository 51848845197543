import { lightFormat, isValid } from 'date-fns';

import { httpClient, singleErrorMessageHandler } from 'utils';
import * as apiHelpers from 'apiHelpers';

export const { addCardToClient } = apiHelpers;

export const createClient = (data) =>
  new Promise((resolve) => {
    const payload = {
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      email: data.email || undefined,
      phone: data.phone || undefined,
      birthdate: data.birthdate ? lightFormat(data.birthdate, 'yyyy-MM-dd') : undefined,
      notes: data.notes || undefined,
      isPrivacyPolicyAccepted: false,
    };

    httpClient
      .post('/clients', payload)
      .then(({ data: { slug } }) => resolve({ success: true, slug }))
      .catch((e) => {
        const errorResult = singleErrorMessageHandler(e);
        resolve(errorResult);
      });
  });

export const editClient = (data) =>
  new Promise((resolve) => {
    const payload = {
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      email: data.email || null,
      phone: data.phone || null,
      birthdate: data.birthdate ? lightFormat(data.birthdate, 'yyyy-MM-dd') : null,
      notes: data.notes || null,
    };

    httpClient
      .put(`/clients/${data.slug}`, payload)
      .then(() => resolve({ success: true }))
      .catch((e) => {
        const errorResult = singleErrorMessageHandler(e);
        resolve(errorResult);
      });
  });

export const renewMembership = (cardSlug, values) =>
  new Promise((resolve) => {
    let dateFrom;
    if (Array.isArray(values.dateFrom)) {
      dateFrom = lightFormat(values.dateFrom[0], 'yyyy-MM-dd');
    } else {
      dateFrom = lightFormat(values.dateFrom, 'yyyy-MM-dd');
    }

    const payload = {
      membershipTypeSlug: values.membershipTypeSlug,
      membershipOfferSlug: values.membershipOfferSlug || null,
      cardSlug,
      paid: 0,
      dateFrom,
    };
    httpClient
      .post('/memberships', payload)
      .then(() => resolve({ success: true }))
      .catch((e) => {
        const errorResult = singleErrorMessageHandler(e);
        resolve(errorResult);
      });
  });

export const getValidBirthdate = (rawBirthdate) => {
  if (!rawBirthdate) {
    return false;
  }

  if (!/^[0-9]?[0-9]\/[0-9]?[0-9]\/[1-9][0-9][0-9][0-9]$/g.test(rawBirthdate)) {
    return false;
  }

  const birthdateParts = rawBirthdate.split('/');
  const birthdate = new Date(`${birthdateParts[2]}-${birthdateParts[1]}-${birthdateParts[0]}`);

  return isValid(birthdate) ? birthdate : false;
};
