import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

export const ActiveOffersTableLayout = ({ columns, getTableRow }) => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'offers',
    columns,
    getTableRow,
    { filters: { isActive: true, sortColumn: 'name' }, alias: 'active-offers' },
  );

  return (
    <C.TableLayout
      isInline
      isLoading={TH.checkIsTableLoading(pageSWRs)}
      columns={columns}
      emptyListSubtitle="Brak aktywnych promocji"
      {...tableLayoutProps}
    />
  );
};
