import React, { PureComponent } from 'react';
import { Formik, Field, Form } from 'formik';

import * as C from 'components';
import { httpClient } from 'utils';

import { LoginActions } from './LoginActions';

const initialValues = { email: '', password: '' };

const loginAction = (username, password) =>
  httpClient
    .post('/auth/login', { username, password })
    .then(res => {
      if (localStorage && localStorage.setItem && res.data && res.data.accessToken) {
        localStorage.setItem('accessToken', res.data.accessToken);
        return { success: true };
      } else {
        return { succes: false, errors: 'Coś poszło nie tak, przepraszamy.' };
      }
    })
    .catch(e => {
      if (e.response && e.response.status === 401) {
        return { success: false, errors: 'Podano niepoprawny adres e-mail lub hasło' };
      }

      return { succes: false, errors: 'Coś poszło nie tak, przepraszamy.' };
    });

export class LoginForm extends PureComponent {
  state = { error: '' };

  submitForm = async ({ email, password }, { setErrors, setSubmitting }) => {
    this.setState({ error: '' });
    const result = await loginAction(email, password);

    if (result && result.success) {
      window.location = '/';
    } else {
      setSubmitting(false);

      if (typeof result.errors === 'string') {
        this.setState({ error: result.errors });
      }
    }
  };

  render() {
    const { isDesktop } = this.props;
    const { error } = this.state;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ isSubmitting }) => (
          <Form>
            <C.Container>
              <C.Title style={{ marginBottom: isDesktop ? '2rem' : '1.5rem' }}>Zaloguj się</C.Title>
              <div>
                <Field
                  type="email"
                  name="email"
                  placeholder="Twój e-mail"
                  disabled={isSubmitting}
                  component={C.Input}
                />
                <Field
                  type="password"
                  name="password"
                  placeholder="Hasło"
                  disabled={isSubmitting}
                  component={C.Input}
                />
              </div>
              {error && <C.ErrorMessage error={error} />}
              <LoginActions isDesktop={isDesktop} isLoading={isSubmitting} />
            </C.Container>
          </Form>
        )}
      />
    );
  }
}
