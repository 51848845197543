import React from 'react';
import { styled } from 'baseui';

import { theme } from 'styles';

const baseStyle = {
  fontFamily: theme.primaryFontFamily,
  color: theme.colors.text,
  margin: '1.25rem auto 1.5rem',
  flexGrow: 1,

  ':first-child': {
    marginTop: 0,
  },
};

const H1Title = styled('h1', ({ $theme }) => ({
  ...baseStyle,
  ...$theme.typography.font660,
}));

const H2Title = styled('h2', ({ $theme }) => ({
  ...baseStyle,
  ...$theme.typography.font560,
}));

export const Title = ({ type, children, ...props }) => {
  switch (type) {
    case 'secondary':
      return <H2Title {...props}>{children}</H2Title>;
    default:
      return <H1Title {...props}>{children}</H1Title>;
  }
};
