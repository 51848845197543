import React from 'react';

import * as C from 'components';

export const RegisterForm = ({ isDesktop }) => (
  <C.Container>
    <C.Title style={{ marginBottom: isDesktop ? '2rem' : '1.5rem' }}>Zarejestruj się</C.Title>
    <C.Input placeholder="Twój e-mail" value="damiandenis@gmail.com" disabled />
    <C.Input placeholder="Imię" />
    <C.Input placeholder="Nazwisko" />
    <C.Input placeholder="Stwórz hasło" type="password" />
    <C.PasswordStrength password="Ala!" />
    <C.Consent>
      Rejestrując się, akceptujesz{' '}
      <C.ConsentLink href="#">Warunki korzystania z portalu</C.ConsentLink> oraz{' '}
      <C.ConsentLink href="#">Politykę prywatności</C.ConsentLink>.
    </C.Consent>
    <C.Button onClick={() => alert('click')}>Zarejestruj się</C.Button>
  </C.Container>
);
