import React from 'react';
import Lottie from 'react-lottie';
import { styled } from 'baseui';

import animationData from 'animations/dots-loader.json';

const PageLoaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

export const PageLoader = () => (
  <PageLoaderWrapper>
    <Lottie options={defaultOptions} height={220} width={220} isClickToPauseDisabled />
  </PageLoaderWrapper>
);
