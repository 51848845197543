import swr from 'swr';
import { httpClient } from './httpClient';

const useSWR = (url, config) => {
  const configModified = {
    ...config,
    // global customizations
  };

  /**
   * Axios has a wrapper object around data => filter response
   */
  const { data: response, ...responseUseSWR } = swr(
    url,
    reqUrl => httpClient.get(reqUrl),
    configModified,
  );
  const { data, ...responseAxios } = response || {};
  return { ...responseUseSWR, responseAxios, data: data || {} };
};

export { useSWR as swr };
