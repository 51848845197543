import React, { useEffect, useState } from 'react';

import { fetchCardDetails, startVisit, endVisit } from 'apiHelpers';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as MC from '../Modal/components';
import * as H from './helpers';
import * as S from './styled';
import { membershipsColumns } from './constants';

export const CardDetails = ({ cardNumber, closeModal, setError }) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const { cardDetails, error, isLoading, ...cardStore } = H.useCardDetailsState();
  const setCardError = setError || cardStore.receiveError;

  useEffect(() => {
    async function fetch(cardNumber) {
      cardStore.fetchCardDetails();
      const cardDetails = await fetchCardDetails(cardNumber);

      if (cardDetails) {
        cardStore.receiveSuccess(cardDetails);
      } else {
        cardStore.receiveError('Nie udało się pobrac informacji o karcie.');
      }
    }

    if (cardNumber) {
      fetch(cardNumber);
    }
  }, [cardNumber]);

  if (error) {
    return <S.ErrorMessage error={error} />;
  }

  if (isLoading || !cardDetails) {
    return <S.Loader />;
  }

  /* Prepare owner data. */
  const ownerName = `${cardDetails.firstName} ${cardDetails.lastName}`;
  const ownerUrl = `/panel/clients/${cardDetails.clientSlug}`;

  /* Prepare memberships columns and rows to display. */
  const isEntracesVisible = cardDetails.memberships.some(membership => membership.entrancesLeft !== null);
  const columns = isEntracesVisible ? membershipsColumns : membershipsColumns.slice(0, membershipsColumns.length - 1);
  const membershipsRows = cardDetails.memberships.map(membership => H.getMembershipRecordRow(membership, isEntracesVisible));
  const rows = TH.transformDataIntoPages(columns, membershipsRows);

  const isMembershipActive = cardDetails.memberships.some(membership => membership.isActive);

  const executeStartVisit = () => startVisit({ cardNumber, closeModal, setIsWaiting, setError: setCardError });
  const executeEndVisit = () => endVisit({ closeModal, setIsWaiting, setError: setCardError, visitSlug: cardDetails.visitSlug });

  return (
    <S.Root>
      <S.CardOwner>Karta należy do: <S.CardOwnerName to={ownerUrl}>{ownerName}</S.CardOwnerName></S.CardOwner>
      {cardDetails.notes && (
        <S.NotesWrapper>
          <S.NotesTitle>Notatki</S.NotesTitle>
          <S.NotesContent>{cardDetails.notes}</S.NotesContent>
        </S.NotesWrapper>
      )}
      <C.StaticInlineTable
        columns={columns}
        rows={rows}
        emptyListTitle='Brak historii karnetów'
      />
      {!!rows.length && (
        <S.HistoryNavLink to={ownerUrl}>Pokaż pełną historię karnetów &#10132;</S.HistoryNavLink>
      )}

      <MC.Footer>
        {/* <MC.Button $isConfirm onClick={executeStartVisit}>
          Odnów karnet
        </MC.Button> */}
        <div />
        {isMembershipActive && (
          <MC.Button
            disabled={!!error}
            $isConfirm
            isLoading={isWaiting}
            onClick={cardDetails.visitSlug ? executeEndVisit : executeStartVisit}
          >
            {cardDetails.visitSlug ? 'Zakończ wizytę' : 'Rozpocznij wizytę'}
          </MC.Button>
        )}
      </MC.Footer>
    </S.Root>
  );
};
