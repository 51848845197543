import { lightFormat } from 'date-fns';

import { httpClient, singleErrorMessageHandler } from 'utils';

export const editMembership = (data) =>
  new Promise((resolve) => {
    const payload = {
      membershipTypeSlug: data.membershipTypeSlug || null,
      membershipOfferSlug: data.membershipOfferSlug || null,
      dateFrom: data.dateFrom ? lightFormat(data.dateFrom, 'yyyy-MM-dd') : undefined,
    };

    httpClient
      .put(`/memberships/${data.slug}`, payload)
      .then(() => resolve({ success: true }))
      .catch((e) => {
        const errorResult = singleErrorMessageHandler(e);
        resolve(errorResult);
      });
  });

export const removeMembership = (slug) => httpClient.delete(`/memberships/${slug}`).catch((e) => console.log(e));
