import React from 'react';
import { styled } from 'baseui';

import { Logo } from 'components/Logo';
import { theme } from 'styles';

const StyledLogo = styled(Logo, {
  position: 'absolute',
  top: '2.25rem',
  left: '2rem',
  padding: '0 !important',
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  padding: '3rem',
  boxSizing: 'border-box',
});

const Card = styled('div', {
  ...theme.borderRadius.lg,
  backgroundColor: '#FFF',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  overflow: 'hidden',
  margin: '0 1.5rem',
  maxWidth: '1170px',
  minHeight: '750px',
  boxSizing: 'border-box',
});

const LeftPanel = styled('div', {
  backgroundImage: `url('${process.env.REACT_APP_BUCKET_URL}/assets/auth-card.jpeg')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'norepeat',
  position: 'relative',
  padding: '2.25rem 2rem',
  width: '43%',
  flexBasis: '43%',
});

const FormWrapper = styled('div', {
  boxSizing: 'content-box',
  padding: '2.25rem 0',
  maxWidth: '410px',
  flexGrow: 1,
});

export const AuthDesktopCard = ({ children, hasBarAction }) => {
  const RightPanel = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '57%',
    flexBasis: '57%',
    position: 'relative',
    paddingTop: hasBarAction ? '4.375rem' : 0,
  });

  return (
    <Wrapper>
      <Card>
        <LeftPanel>
          <StyledLogo color="white" />
        </LeftPanel>
        <RightPanel>
          <FormWrapper>{children}</FormWrapper>
        </RightPanel>
      </Card>
    </Wrapper>
  );
};
