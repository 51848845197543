import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import { visitsColumns } from './constants';
import { getVisitsTableRow as getTableRow } from './helpers';

export const ClientDetailsVisitsList = ({ clientSlug }) => {
  const options = {
    isSearchExculded: true,
    filters: { clientSlug },
  };
  const { pageSWRs, pages, ...tableLayoutProps } = hooks.useTableLayoutData('visits', visitsColumns, getTableRow, options);

  return (
    <C.TableLayout
      isInline
      isLoading={TH.checkIsTableLoading(pageSWRs)}
      columns={visitsColumns}
      pages={pages}
      emptyListSubtitle="Brak wizyt"
      {...tableLayoutProps}
    />
  );
};
