import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';
import * as S from '../styled';

export const getTableRow = (item, isEmployee, openEditModal, toggleAction) => {
  let label;
  let labelColor;
  if (item.isActive) {
    label = 'Aktywna';
    labelColor = 'success';
  } else {
    label = 'Nieaktywna';
    labelColor = 'inactive';
  }

  let discount;
  if (item.type === 'fixed') {
    discount = `${item.typeValue}zł`;
  } else {
    discount = `${item.typeValue}%`;
  }

  /* Prepare menu items. */
  // const editAction = () => openEditModal(item);
  // const menuItems = [{ label: 'Edytuj', action: editAction }];
  const menuItems = [];
  if (item.isActive) {
    menuItems.push({ label: 'Dezaktywuj', action: () => toggleAction(item) });
  } else {
    menuItems.push({ label: 'Aktywuj', action: () => toggleAction(item) });
  }

  const createdAt = format(new Date(item.createdAt), 'd MMM yyyy', { locale: plLocale });

  const content = [
    <S.TableRowOfferDetails {...item} />,
    <TC.TableRowTextField>{discount}</TC.TableRowTextField>,
    <TC.TableRowTextField>{`${item.membershipTypePrice}zł`.replace('$', '').replace('.', ',')}</TC.TableRowTextField>,
    <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
    <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>,
  ];
  if (!isEmployee) {
    content.push(<TC.TableRowActionsButton items={menuItems} />);
  }
  return { key: item.slug, content };
};
