import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';

import { httpClient } from 'utils';

import * as C from 'components';
import * as MC from 'components/Modal/components';
import * as F from 'modals/forms';

import * as H from '../helpers';
import * as S from './styled';

export class EditMembershipModal extends PureComponent {
  state = {
    isDone: false,
    isMounted: true,
    membershipItems: [],
    offersItems: [],
    error: '',
  };

  async componentDidMount() {
    try {
      const {
        data: { items: membershipTypes },
      } = await httpClient('/membership-types');
      const filteredMT = membershipTypes.filter(mt => mt.isActive);
      const {
        data: { items: offers },
      } = await httpClient('/offers');
      const filteredOffers = offers.filter(offer => offer.isActive);

      const membershipItems = filteredMT.map(mt => ({ label: mt.name, value: mt.slug }));
      const offersItems = filteredOffers.map(o => ({
        label: o.name,
        value: o.slug,
        membershipTypeSlug: o.membershipTypeSlug,
      }));

      this.setState({ membershipItems, offersItems });
    } catch {
      this.setState({
        error:
          'Wystąpił błąd podczas pobierania listy dostępnych karnetów. Prosimy spróbować ponownie później.',
      });
    }
  }

  onClose = () => {
    this.props.onClose();
    this.setState({ isDone: false, isMounted: true, error: '' });
  };

  submitForm = async (values, { setErrors, setSubmitting }) => {
    this.setState({ error: '' });

    const result = await H.editMembership(values);

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        setTimeout(() => this.setState({ isMounted: false }), 2000);
        setTimeout(() => {
          this.onClose();
          this.props.revalidate();
        }, 2250);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ error });
    setSubmitting(false);
  };

  render() {
    const { isOpen, membership } = this.props;
    const { isDone, isMounted, error, membershipItems, offersItems } = this.state;

    const initialValues = getInitialValues(membership);

    return (
      <C.Modal isOpen={isOpen} onClose={this.onClose} title="Edytuj karnet">
        <S.Root>
          {isDone ? (
            <MC.StepWrapper $isMounted={isMounted}>
              <MC.CenteredContent>
                <MC.Done title="Dane karnetu zostały zapisane" />
              </MC.CenteredContent>
            </MC.StepWrapper>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={(values, actions) =>
                this.submitForm({ ...values, slug: membership.slug }, actions)
              }
              render={({ isSubmitting, values }) => (
                <Form autoComplete="off">
                  <F.MembershipForm
                    isSubmitting={isSubmitting}
                    membershipItems={membershipItems}
                    offersItems={offersItems}
                    selectedMembershipType={values.membershipTypeSlug}
                  />
                  {error && <C.ErrorMessage error={error} />}
                  <MC.Footer>
                    <div />
                    <MC.Button
                      $isConfirm
                      disabled={isSubmitting || !values.membershipTypeSlug || !values.dateFrom}
                      type="submit"
                    >
                      Zapisz
                    </MC.Button>
                  </MC.Footer>
                </Form>
              )}
            />
          )}
        </S.Root>
      </C.Modal>
    );
  }
}

/**
 *    Helpers.
 */
const getInitialValues = membership => ({
  membershipTypeSlug: membership.membershipTypeSlug || '',
  membershipOfferSlug: membership.membershipOfferSlug || '',
  dateFrom: membership.dateFrom ? new Date(membership.dateFrom) : null,
});
