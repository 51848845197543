export const visitsColumns = [
  {
    title: 'Data wejścia',
    direction: 'DESC',
    width: '160px',
  },
  {
    title: 'Wyjście',
    direction: null,
    width: '140px',
  },
  {
    title: 'Czas spędzony',
    direction: null,
    width: '140px',
  },
  {
    title: 'Status',
    direction: null,
    width: '140px',
  },
];
