import React from 'react';
import { styled } from 'baseui';

import { ReactComponent as ScanSvg } from './scan.svg';
import { ReactComponent as CloseSvg } from './close.svg';

const Button = styled('button', ({ $theme, $isOpen }) => ({
  backgroundColor: 'rgba(255,255,255,0.1)',
  borderRadius: '1.25rem',
  border: 'none',
  position: 'absolute',
  top: '1.25rem',
  right: '1.25rem',
  color: '#FFF',
  textTransform: 'uppercase',
  height: '2.5rem',
  overflow: 'hidden',
  outline: 'none',
  width: $isOpen ? '2.5rem' : '6.25rem',
  transition: `${$theme.animation.timing100} width ${$theme.animation.easeOutCurve}`,
}));

const ButtonContent = styled('div', ({ $theme, $isVisible }) => ({
  height: '2.5rem',
  width: '6.25rem',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%)',
  pointerEvents: 'none',
  whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...$theme.typography.font200,
  letterSpacing: '1px',
  opacity: $isVisible ? 1 : 0,
  transition: `${$theme.animation.timing100} opacity ${$theme.animation.easeOutCurve}`,
}));

const StyledScanSvg = styled(ScanSvg, {
  marginLeft: '0.5rem',
});

export const ScanButton = ({ isOpen, onClick }) => (
  <Button type="button" $isOpen={isOpen} onClick={onClick}>
    <ButtonContent $isVisible={isOpen}>
      <CloseSvg />
    </ButtonContent>
    <ButtonContent $isVisible={!isOpen}>
      Skanuj <StyledScanSvg />
    </ButtonContent>
  </Button>
);
