import React from 'react';
import { styled } from 'baseui';

const FirstLine = styled('p', ({ $theme }) => ({
  ...$theme.typography.font260,
  color: $theme.colors.text,
  margin: 0,
}));
const SecondLine = styled('p', ({ $theme }) => ({
  ...$theme.typography.font100,
  color: $theme.colors.text200,
  margin: 0,
}));

export const ClientOption = ({ name, phone, email }) => (
  <>
    <FirstLine>{name}</FirstLine>
    <SecondLine>
      {phone}
      {phone && email ? ', ' : ''}
      {email}
      {!phone && !email && 'brak danych'}
    </SecondLine>
  </>
);
