import React from 'react';

import * as hooks from 'hooks';
import { useAuthContext } from 'context/AuthContext';

import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as M from './modals';
import * as H from './helpers';

const columns = [
  {
    title: 'Nazwisko',
    direction: null,
  },
  {
    title: 'Karta',
    direction: null,
    width: '160px',
  },
  {
    title: 'Nr telefonu',
    direction: null,
    width: '160px',
  },
  {
    title: 'Data dołączenia',
    direction: null,
    width: '160px',
  },
  {
    title: 'Status',
    direction: null,
    width: '140px',
  },
  {
    title: '',
    width: '68px',
  },
];

export const Clients = () => {
  const { me } = useAuthContext();

  const clientStore = H.useClientState();
  const getTableRow = (item, openConfirmModal) =>
    H.getTableRow(
      item,
      openConfirmModal,
      clientStore.openEditModal,
      clientStore.openCardModal,
      clientStore.openRenewModal,
    );

  const {
    isOpen,
    recordSlug,
    recordName,
    pageSWRs,
    closeConfirmModal,
    search,
    ...tableLayoutProps
  } = hooks.useTableLayoutData('clients', columns, getTableRow);

  const revalidate = () => pageSWRs.forEach(page => page.revalidate());

  const confirmModalAction = async () => {
    await H.removeClientAction(recordSlug);
    revalidate();
    closeConfirmModal();
  };

  const closeCreateModal = () => {
    revalidate();
    clientStore.closeCreateModal();
  };

  const client = clientStore.client || {};

  /* Prepare empty list titles. */
  let emptyListTitle;
  let emptyListSubtitle;
  if (me.role === 'employee' && (!search || search.length < 3)) {
    emptyListTitle = 'Użyj wyszukiwarki, aby znaleźć klienta';
    emptyListSubtitle = '';
  }

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
        Widok mobilny w przygotowaniu...
      </C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout
          isLoading={TH.checkIsTableLoading(pageSWRs)}
          title="Klienci"
          buttonLabel="Dodaj nowego klienta"
          buttonAction={clientStore.openCreateModal}
          columns={columns}
          emptyListTitle={emptyListTitle}
          emptyListSubtitle={emptyListSubtitle}
          {...tableLayoutProps}
        />
      </C.ResponsiveWrapper>
      <C.ConfirmModal
        isOpen={isOpen}
        close={closeConfirmModal}
        confirmLabel="Usuń klienta"
        confirmAction={confirmModalAction}
        cancelLabel="Nie usuwaj"
      >
        Jesteś pewien, że chcesz usunąć następującego klienta:{' '}
        <C.ModalRecordName>{recordName}</C.ModalRecordName>?
      </C.ConfirmModal>
      <M.CreateClientModal isOpen={clientStore.isCreateModalOpen} onClose={closeCreateModal} />
      <M.EditClientModal
        isOpen={clientStore.isEditModalOpen}
        onClose={clientStore.closeEditModal}
        client={client}
        revalidate={revalidate}
      />
      <M.CardModal
        isOpen={clientStore.isCardModalOpen}
        onClose={clientStore.closeCardModal}
        cardNumber={client.cardNumber}
        clientSlug={client.slug}
        revalidate={revalidate}
      />
      <M.RenewModal
        isOpen={clientStore.isRenewModalOpen}
        onClose={clientStore.closeRenewModal}
        cardSlug={client.cardSlug}
        clientSlug={client.slug}
        revalidate={revalidate}
      />
    </C.Layout>
  );
};
