import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';
import * as S from '../styled';

export const getTableRow = item => {
  let label;
  let labelColor;
  if (item.isActive) {
    label = 'Aktywny';
    labelColor = 'success';
  } else {
    label = 'Nieaktywny';
    labelColor = 'inactive';
  }

  let type;
  let value;
  if (item.type === 'periodic') {
    type = 'Okresowy';
    switch (item.expiresType) {
      case 'day':
        value = `${item.expiresValue} ${item.expiresValue === 1 ? 'dzień' : 'dni'}`;
        break;
      case 'week':
        value = `${item.expiresValue} ${item.expiresValue === 1 ? 'tydzień' : 'tygodnie'}`;
        break;
      case 'month':
        value = `${item.expiresValue} ${item.expiresValue === 1 ? 'miesiąc' : 'miesiące'}`;
        break;
      default:
        value = '';
    }
  } else {
    type = 'Liczba wejść';
    value = item.entrances;
  }

  const createdAt = format(new Date(item.createdAt), 'd MMM yyyy', { locale: plLocale });

  return {
    key: item.slug,
    content: [
      <TC.TableRowTextField>{item.name}</TC.TableRowTextField>,
      <S.TableRowOfferDetails type={type} value={value} />,
      <TC.TableRowTextField>
        {`${item.price}zł`.replace('$', '').replace('.', ',')}
      </TC.TableRowTextField>,
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
      <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>,
    ],
  };
};
