import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

export const getVisitsTableRow = (item) => {
  let label;
  let labelColor;
  if (item.leavesAt) {
    label = 'Zakończona';
    labelColor = 'success';
  } else {
    label = 'Wizyta trwa';
    labelColor = 'warning';
  }

  const entersAt = format(new Date(item.entersAt), 'HH:mm d MMM yyyy', { locale: plLocale });
  const leavesAt = item.leavesAt ? format(new Date(item.leavesAt), 'HH:mm', { locale: plLocale }) : null;

  return {
    key: item.slug,
    content: [
      <TC.TableRowTextField>{entersAt}</TC.TableRowTextField>,
      <TC.TableRowTextField>{leavesAt || ''}</TC.TableRowTextField>,
      <TC.TableRowTextField>{item.timeSpent || ''}</TC.TableRowTextField>,
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
    ],
  };
};
