export const getRecordDetailsItems = (recordDetails) => {
  if (!recordDetails || !recordDetails.firstName || !recordDetails.lastName) {
    return [];
  }

  const result = [
    { label: 'Imię', value: recordDetails.firstName },
    { label: 'Nazwisko', value: recordDetails.lastName },
    { label: 'E-mail', value: recordDetails.email },
    { label: 'Numer telefonu', value: recordDetails.phone || 'Brak' },
  ];

  return result;
};
