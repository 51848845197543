import React from 'react';

import { SortableHeadCell } from 'baseui/table';

import * as TC from './components';
import * as S from './styled';

export const StaticInlineTable = ({
  columns,
  rows,
  emptyListTitle,
  emptyListSubtitle,
}) => (
  <TC.TableRoot isInline={true}>
    <S.Table>
      <S.Head>
        {columns.map(({ width, ...column }) => (
          <SortableHeadCell
            key={column.title}
            {...column}
            overrides={{
              HeadCell: {
                style: {
                  borderWidth: 0,
                  width,
                  flex: width ? `0 ${width}` : '1',
                  boxSizing: 'border-box',
                },
              },
              SortableLabel: {
                style: ({ $theme }) => ({
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  ...$theme.typography.font260,
                  color: $theme.colors.text100,
                }),
              },
              SortDirectionIcon: {
                style: { marginLeft: '0.25rem' },
              },
            }}
          />
        ))}
      </S.Head>
      <S.Body $isLoading={false}>
        {rows}
        {!rows.length && (
          <TC.TableEmptyListIndicator
            title={emptyListTitle}
            subtitle={emptyListSubtitle}
            isListEmpty
          />
        )}
      </S.Body>
    </S.Table>
  </TC.TableRoot>
);
