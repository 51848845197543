import React from 'react';

import * as C from 'components';

import * as RPC from './components';

export const ResetPassword = () => (
  <>
    <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
      <C.LogoAndCenteredContentWrapper hasFooterAction>
        <RPC.ResetPasswordForm />
      </C.LogoAndCenteredContentWrapper>
    </C.ResponsiveWrapper>
    <C.ResponsiveWrapper size="lg">
      <C.AuthDesktopCard>
        <RPC.ResetPasswordForm isDesktop />
      </C.AuthDesktopCard>
    </C.ResponsiveWrapper>
  </>
);
