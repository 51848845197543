import { useReducer } from 'react';

const OPEN_MODAL = 'useModalState/open';
const CLOSE_MODAL = 'useModalState/close';
const START_ACTION = 'useModalState/startAction';
const RECEIVE_ERROR = 'useModalState/receiveError';

const initialState = {
  isModalOpen: false,
  isModalWaiting: false,
  modalType: null,
  modalData: null,
  modalError: null,
};

function reducer(state, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...initialState,
        isModalOpen: true,
        modalType: action.modalType,
        modalData: action.modalData,
      };
    case CLOSE_MODAL:
      return { ...state, isModalOpen: false };
    case START_ACTION:
      return { ...state, isModalWaiting: true };
    case RECEIVE_ERROR:
      return { ...state, isModalWaiting: false, modalError: action.modalError };
    default:
      throw new Error();
  }
}

export const useModalState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    /* Data. */
    ...state,
    /* Actions. */
    openModal: (modalType, modalData = null) =>
      dispatch({ type: OPEN_MODAL, modalType, modalData }),
    closeModal: () => dispatch({ type: CLOSE_MODAL }),
    startAction: () => dispatch({ type: START_ACTION }),
    receiveError: modalError => dispatch({ type: RECEIVE_ERROR, modalError }),
  };
};
