import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as H from './helpers';

const columns = [
  {
    title: 'Imię i nazwisko',
    direction: null,
  },
  {
    title: 'Nr telefonu',
    direction: null,
    width: '160px',
  },
  {
    title: 'Osttni karnet',
    direction: null,
    width: '180px',
  },
  {
    title: 'Data od',
    direction: null,
    width: '160px',
  },
  {
    title: 'Data do',
    direction: null,
    width: '160px',
  },
];

export const ChurnedClients = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/churned-clients',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy nie przedłużyli karnetu w ciągu ostatnich 28 dni" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

export const ChurnedClients202011 = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/churned-clients-2020-11',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy zakupili karnet w październiku i nie przedłużyli go w listopadzie 2020" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

export const ChurnedClients2020Q1 = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/churned-clients-2020-q1',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy nie przedłużyli karnetu od pierwszego kwartału 2020" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

export const ChurnedClients2020Q2 = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/churned-clients-2020-q2',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy nie przedłużyli karnetu od drugiego kwartału 2020" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

export const ChurnedClients2020Q3 = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/churned-clients-2020-q3',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy nie przedłużyli karnetu od drugiego kwartału 2020" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

export const ChurnedClients202103 = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/churned-clients-2021-03',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy nie przedłużyli karnetu od marca 2021" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

export const CustomReport20201227 = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'reports/custom-report-2020-12-27',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Klienci którzy przedłużyli karnet od 1 października 2020" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};

