import React from 'react';
import { styled } from 'baseui';

import * as TC from 'components/TableLayout/components';

const ClientWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const ClientEmail = styled('p', ({ $theme }) => ({
  ...$theme.typography.font100,
  color: $theme.colors.text100,
  margin: 0,
  width: '100%',
}));

export const TableRowClientDetails = ({ firstName, lastName, slug, email }) => (
  <ClientWrapper>
    <TC.TableRowNavLink
      to={`/panel/clients/${slug}`}
    >{`${firstName} ${lastName}`}</TC.TableRowNavLink>
    {email && <ClientEmail>{email}</ClientEmail>}
  </ClientWrapper>
);
