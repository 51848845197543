import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

export const getTableRow = (item) => {
  const dateFrom = format(new Date(item.datefrom), 'd MMM yyyy', { locale: plLocale });
  const dateTo = format(new Date(item.dateto), 'd MMM yyyy', { locale: plLocale });
  const name = `${item.firstname} ${item.lastname}`;

  return {
    key: item.slug,
    content: [
      <TC.TableRowNavLink to={`/panel/clients/${item.slug}`}>{name}</TC.TableRowNavLink>,
      <TC.TableRowTextField>{item.phone}</TC.TableRowTextField>,
      <TC.TableRowTextField>{item.membershipname}</TC.TableRowTextField>,
      <TC.TableRowTextField>{dateFrom}</TC.TableRowTextField>,
      <TC.TableRowTextField>{dateTo}</TC.TableRowTextField>],
  };
};
