import React from 'react';
import { Router as ReactRouter, Switch, Route } from 'react-router-dom';

import { history } from 'utils';

import { DefaultDashboardRoute, PrivateRoute, PublicRoute } from 'components/auth';

import * as P from 'pages';

export const Router = () => (
  <ReactRouter history={history}>
    <Switch>
      <Route exact path="/">
        <DefaultDashboardRoute />
      </Route>
      <PublicRoute path="/login" component={P.Login} />
      <PublicRoute path="/register" component={P.Register} />
      <PublicRoute path="/reset-password" component={P.ResetPassword} />
      <PrivateRoute path="/panel/clients/:slug" component={P.ClientDetails} />
      <PrivateRoute path="/panel/clients" component={P.Clients} />
      <PrivateRoute path="/panel/employees/:slug" component={P.EmployeeDetails} />
      <PrivateRoute path="/panel/employees" component={P.Employees} />
      <PrivateRoute path="/panel/membership-types" component={P.MembershipTypes} />
      <PrivateRoute path="/panel/memberships" component={P.Memberships} />
      <PrivateRoute path="/panel/offers" component={P.Offers} />
      <PrivateRoute path="/panel/trainings" component={P.Dashboard} />
      <PrivateRoute path="/panel/visits" component={P.Visits} />
      <PrivateRoute path="/panel/logs" component={P.CrudLogs} />
      <PrivateRoute path="/panel/reports/churned-clients-2020-11" component={P.ChurnedClients202011} />
      <PrivateRoute path="/panel/reports/churned-clients-2020-q1" component={P.ChurnedClients2020Q1} />
      <PrivateRoute path="/panel/reports/churned-clients-2020-q2" component={P.ChurnedClients2020Q2} />
      <PrivateRoute path="/panel/reports/churned-clients-2020-q3" component={P.ChurnedClients2020Q3} />
      <PrivateRoute path="/panel/reports/churned-clients-2021-03" component={P.ChurnedClients202103} />
      <PrivateRoute path="/panel/reports/churned-clients" component={P.ChurnedClients} />
      <PrivateRoute path="/panel/reports/custom-report-2020-12-27" component={P.CustomReport20201227} />
      <PrivateRoute path="/panel" component={P.Dashboard} />
    </Switch>
  </ReactRouter>
);
