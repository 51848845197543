import React from 'react';

import * as apiHelpers from 'apiHelpers';
import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';
import { useAuthContext } from 'context';
import * as M from 'pages/Clients/modals';

import { cardsColumns } from './constants';
import { getCardsTableRow } from './helpers';

export const ClientDetailsCardsList = ({ clientSlug, isCardModalOpen, closeCardModal }) => {
  const { me } = useAuthContext();
  const columns = me.role === 'owner' ? cardsColumns : cardsColumns.slice(0, cardsColumns.length - 1);

  const options = {
    isSearchExculded: true,
    filters: { clientSlug, sortColumn: 'dateTo' },
  };
  const route = `clients/${clientSlug}/cards`;
  const getTableRow = (item, openConfirmModal) => getCardsTableRow(item, openConfirmModal, me.role);
  const {
    pageSWRs,
    pages,
    isOpen,
    recordName,
    recordSlug,
    openConfirmModal,
    closeConfirmModal,
    confirmModalAdditionalData,
    ...tableLayoutProps
  } = hooks.useTableLayoutData(route, columns, getTableRow, options);
  const { actionType } = confirmModalAdditionalData;

  const revalidate = () => pageSWRs.forEach(page => page.revalidate());
  const confirmModalAction = async () => {
    await apiHelpers.updateClientCard(recordSlug, actionType);
    revalidate();
    closeConfirmModal();
  };

  let actionTypeLabel = '';
  let confirmLabel = '';
  switch (actionType) {
    case 'active': actionTypeLabel = 'aktywować'; confirmLabel = 'Aktywuj'; break;
    case 'inactive': actionTypeLabel = 'dezaktywować'; confirmLabel = 'Dezaktywuj'; break;
    case 'blocked': actionTypeLabel = 'zablokować'; confirmLabel = 'Zablokuj'; break;
    case 'lost': actionTypeLabel = 'oznaczyć jako zgubioną'; confirmLabel = 'Oznacz jako zgubioną'; break;
    case 'remove': actionTypeLabel = 'usunąć'; confirmLabel = 'Usuń'; break;
    default:
  }

  return (
    <>
      <C.TableLayout
        isInline
        isLoading={TH.checkIsTableLoading(pageSWRs)}
        columns={columns}
        pages={pages}
        emptyListSubtitle="Brak przypisanej karty"
        {...tableLayoutProps}
      />
      <C.ConfirmModal
        isOpen={isOpen}
        close={closeConfirmModal}
        confirmLabel={confirmLabel}
        confirmAction={confirmModalAction}
        cancelLabel="Anuluj"
      >
        Czy na pewno chcesz {actionTypeLabel} kartę o następującym numerze:{' '}
        <C.ModalRecordName>{recordName}</C.ModalRecordName>?
      </C.ConfirmModal>
      <M.CardModal
        isOpen={isCardModalOpen}
        onClose={closeCardModal}
        clientSlug={clientSlug}
        revalidate={revalidate}
      />
    </>
  );
};
