import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'open-create':
      return { ...initialState, isCreateModalOpen: true };
    case 'close-create':
      return initialState;
    case 'open-edit':
      return { ...initialState, isEditModalOpen: true, client: action.client };
    case 'close-edit':
      return initialState;
    case 'open-card':
      return { ...initialState, isCardModalOpen: true, client: action.client };
    case 'close-card':
      return { ...state, isCardModalOpen: false };
    case 'open-renew':
      return { ...initialState, isRenewModalOpen: true, client: action.client };
    case 'close-renew':
      return { ...state, isRenewModalOpen: false };
    default:
      throw new Error();
  }
}

const initialState = {
  isCreateModalOpen: false,
  isEditModalOpen: false,
  isCardModalOpen: false,
  isRenewModalOpen: false,
  client: null,
};

export function useClientState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    isCreateModalOpen: state.isCreateModalOpen,
    isEditModalOpen: state.isEditModalOpen,
    isCardModalOpen: state.isCardModalOpen,
    isRenewModalOpen: state.isRenewModalOpen,
    client: state.client,
    openCreateModal: () => dispatch({ type: 'open-create' }),
    closeCreateModal: () => dispatch({ type: 'close-create' }),
    openEditModal: client => dispatch({ type: 'open-edit', client }),
    closeEditModal: () => dispatch({ type: 'close-edit' }),
    openCardModal: client => dispatch({ type: 'open-card', client }),
    closeCardModal: () => dispatch({ type: 'close-card' }),
    openRenewModal: client => dispatch({ type: 'open-renew', client }),
    closeRenewModal: () => dispatch({ type: 'close-renew' }),
  };
}
