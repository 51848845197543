import React from 'react';
import { withRouter } from 'react-router-dom';

import { StatefulPopover } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { useAuthContext } from 'context/AuthContext';
import * as C from 'components';

import { Dropdown, MenuButton, RightColumnWrapper, CheckCardStatus } from './components';
import { getRoutes } from './routes';
import * as S from './styled';

export const Navigation = withRouter(({ history }) => {
  const { me, isLoading } = useAuthContext();
  const isVisible = !isLoading && me;

  const items = getRoutes(me.role);

  return (
    <S.Wrapper>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
        <C.Logo />
        {isVisible && (
          <StatefulPopover
            content={({ close }) => (
              <StatefulMenu
                onItemSelect={({ item }) => {
                  close();
                  history.push(item.to);
                }}
                items={items}
              />
            )}
          >
            <MenuButton />
          </StatefulPopover>
        )}
      </C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <S.StyledDesktopWrapper>
          <C.Logo style={{ marginRight: '6.25rem' }} />
          <div>
            {isVisible &&
              items.map(item => (
                <S.StyledNavLink
                  exact
                  key={item.label}
                  to={item.to}
                  $pathname={history.location.pathname}
                >
                  {item.label}
                </S.StyledNavLink>
              ))}
          </div>
          <RightColumnWrapper>
            {['owner', 'employee'].includes(me.role) && <CheckCardStatus />}
            <Dropdown name={`${me.firstName} ${me.lastName}`} role={me.role} />
          </RightColumnWrapper>
        </S.StyledDesktopWrapper>
      </C.ResponsiveWrapper>
    </S.Wrapper>
  );
});
