import React from 'react';
import { Button, SHAPE } from 'baseui/button';
import Plus from 'baseui/icon/plus';

import { withWidth } from 'hoc';

export const PillButton = withWidth(({ children, width, icon, BaseButtonOverrides, ...props }) => {
  let endEnhancer;
  switch (icon) {
    case 'plus':
      endEnhancer = () => <Plus />;
      break;
    default:
      endEnhancer = undefined;
      break;
  }

  const baseButtonOverrides = BaseButtonOverrides || {};
  return (
    <Button
      {...props}
      shape={SHAPE.pill}
      endEnhancer={endEnhancer}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => {
            const height = width < $theme.breakpoints.large ? '2.125rem' : '2.5rem';
            const padding = {
              paddingLeft: '1.875rem',
              paddingRight: '1.875rem',
              paddingTop: '0.75rem',
              paddingBottom: '0.75rem',
            };

            return {
              ...$theme.typography.font200,
              boxSizing: 'border-box',
              height,
              ...padding,
              lineHeight: 1,
              ...baseButtonOverrides,
            };
          },
        },
      }}
    >
      {children}
    </Button>
  );
});
