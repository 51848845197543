import React from 'react';
import { useStyletron } from 'baseui';

import { checkPassStrength } from 'helpers';

const Item = ({ position, strength }) => {
  const [css, theme] = useStyletron();

  const className = css({
    display: 'block',
    backgroundColor: strength.value < position ? theme.colors.border : strength.color,
    height: '0.25rem',
    width: 'calc(20% - 0.25rem)',
    margin: '0 0 0 0.25rem',
    borderRadius: '0.25rem',

    ':first-child': {
      marginLeft: 0,
    },
  });

  return <span className={className} />;
};

export const PasswordStrength = ({ password }) => {
  const [css, theme] = useStyletron();

  const strength = checkPassStrength(password);

  const rootClassName = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  });

  const wrapperClassName = css({
    width: 'calc(100% - 3.75rem)',
    display: 'flex',
    flexDirection: 'row',
  });

  const labelClassName = css({
    ...theme.typography.font100,
    fontFamily: theme.primaryFontFamily,
    color: strength.color,
    margin: 0,
    width: '3.75rem',
    textAlign: 'right',

    ':first-child': {
      marginTop: 0,
    },
  });

  return (
    <div className={rootClassName}>
      <div className={wrapperClassName}>
        <Item position={1} strength={strength} />
        <Item position={2} strength={strength} />
        <Item position={3} strength={strength} />
        <Item position={4} strength={strength} />
        <Item position={5} strength={strength} />
      </div>
      <p className={labelClassName}>{strength.label}</p>
    </div>
  );
};
