import { withStyle } from 'baseui';
import { ModalFooter } from 'baseui/modal';

export const Footer = withStyle(ModalFooter, ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderTopWidth: 0,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  boxSizing: 'border-box',
  paddingTop: '1.875rem',
  paddingBottom: '1.875rem',
  paddingRight: '1.875rem',
  paddingLeft: '1.875rem',
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  pointerEvents: 'none',
}));
