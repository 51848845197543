import axios from 'axios';

function onRequest(config) {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
}

const httpClient = () => {
  const defaultOptions = { baseURL: process.env.REACT_APP_BASE_URL, method: 'get' };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(onRequest);

  return instance;
};

const instance = httpClient();

export { instance as httpClient };
