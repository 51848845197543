import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'open-edit':
      return { ...initialState, isEditModalOpen: true, offer: action.offer };
    case 'close-edit':
      return initialState;
    default:
      throw new Error();
  }
}

const initialState = {
  isEditModalOpen: false,
  offer: null,
};

export function useOffersState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    isEditModalOpen: state.isEditModalOpen,
    offer: state.offer,
    openEditModal: (offer) => dispatch({ type: 'open-edit', offer }),
    closeEditModal: () => dispatch({ type: 'close-edit' }),
  };
}
