import { styled } from 'baseui';

export const Wrapper = styled('div', {
  maxWidth: '375px',
  margin: 0,
});

export const CardWrapper = styled('div', ({ $theme, $companyName }) => ({
  position: 'relative',
  paddingTop: '62%',
  backgroundImage: `url('${process.env.REACT_APP_BUCKET_URL}/company-card/${$companyName}.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'norepeat',
}));

export const Root = styled('div', ({ $theme, $isVisible }) => ({
  opacity: $isVisible ? 1 : 0,
  transition: `${$theme.animation.timing100} opacity ${$theme.animation.easeOutCurve}`,
}));

export const Details = styled('div', ({ $theme }) => ({
  position: 'absolute',
  bottom: '1.25rem',
  left: '1.25rem',
  width: 'calc(100% - 2.5rem)',
}));

export const Name = styled('p', ({ $theme }) => ({
  ...$theme.typography.font500,
  fontFamily: $theme.primaryFontFamily,
  color: '#FFF',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  margin: '0 auto 0.5rem',
}));

export const Membership = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Label = styled('p', ({ $theme }) => ({
  ...$theme.typography.font400,
  fontFamily: $theme.primaryFontFamily,
  color: $theme.colors.text100,
  margin: 0,
}));

export const ExpireDate = styled('p', ({ $theme }) => ({
  ...$theme.typography.font400,
  fontFamily: $theme.primaryFontFamily,
  color: '#FFF',
  margin: 0,
}));

export const CompanyLogo = styled('div', ({ $theme, $companyName }) => ({
  width: '4.5rem',
  height: '4.5rem',
  position: 'absolute',
  left: '1.25rem',
  top: '1rem',
  backgroundImage: `url('${process.env.REACT_APP_BUCKET_URL}/company-logo/${$companyName}-70x70.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'norepeat',
}));

export const BarcodeWrapper = styled('div', ({ $theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: '0.5rem',
  transform: 'translate(-50%)',
}));

export const BarcodeTitle = styled('p', ({ $theme }) => ({
  ...$theme.typography.font500,
  fontFamily: $theme.primaryFontFamily,
  color: '#FFF',
  margin: 0,
  position: 'absolute',
  left: '0',
  top: '30%',
  width: '100%',
  textAlign: 'center',
}));
