import React, { createRef } from 'react';

import { Input as BUIInput } from 'baseui/input';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { withWidth } from 'hoc';

import * as styleOverrides from './style-overrides';
import { ReactComponent as ArrowDown } from './arrow-down.svg';

export const Dropdown = withWidth(({ field, form, width, RootOverrides, items, ...props }) => {
  const inputRef = createRef();

  /* Prepare style overrides. */
  const inputOverrides = getInputOverrides(props, width, RootOverrides);

  /* Prepare selected value. */
  const value = form.values[field.name] || '';
  const selectedItem = items.find(i => i.value === value)
    ? items.find(i => i.value === value).label
    : '';

  /* Check if outdated value is still selected. */
  if (value && !selectedItem) {
    setTimeout(() => form.setFieldValue(field.name, ''), 0);
  }

  return (
    <StatefulPopover
      placement={PLACEMENT['bottomRight']}
      overrides={styleOverrides.statefulPopover}
      content={({ close }) => (
        <StatefulMenu
          overrides={styleOverrides.statefulMenu}
          onItemSelect={({ item }) => {
            form.setFieldValue(field.name, item.value);
            close();
          }}
          items={items}
        />
      )}
    >
      <div style={{ cursor: 'pointer', pointerEvents: props.disabled ? 'none' : 'all' }}>
        <BUIInput
          {...props}
          inputRef={inputRef}
          value={selectedItem}
          endEnhancer={() => <ArrowDown />}
          overrides={inputOverrides}
        />
      </div>
    </StatefulPopover>
  );
});

/**
 *        Helpers.
 */
const getInputOverrides = (props, width, RootOverrides) => {
  /* Prepare style overrides. */
  const backgroundColor = props.backgroundColor || '#FFF';
  const rootOverrides = RootOverrides || {};
  const overrideProps = {
    width,
    backgroundColor,
    rootOverrides,
    disabled: props.disabled,
    placeholder: props.placeholder,
  };

  return styleOverrides.getBUIInputOverrides(overrideProps);
};
