import React from 'react';

import * as C from 'components';
import * as RC from './components';

const AuthAction = props => (
  <C.AuthAction {...props}>
    Masz już konto? <C.AuthActionLink to="/login">Zaloguj się</C.AuthActionLink>
  </C.AuthAction>
);

export const Register = () => (
  <>
    <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
      <C.LogoAndCenteredContentWrapper hasFooterAction>
        <RC.RegisterForm />
      </C.LogoAndCenteredContentWrapper>
      <AuthAction position="bottom" />
    </C.ResponsiveWrapper>
    <C.ResponsiveWrapper size="lg">
      <C.AuthDesktopCard hasBarAction>
        <RC.RegisterForm isDesktop />
        <AuthAction position="top" />
      </C.AuthDesktopCard>
    </C.ResponsiveWrapper>
  </>
);
