import React from 'react';
import Lottie from 'react-lottie';
import { styled } from 'baseui';

import animationData from 'animations/dots-loader.json';

const LoadingListWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '21rem',
});

const AbsoluteLoadingWrapper = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255,0.3)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const TableLoadingListIndicator = ({ isListEmpty }) =>
  isListEmpty ? (
    <LoadingListWrapper>
      <Lottie options={defaultOptions} height={180} width={180} isClickToPauseDisabled />
    </LoadingListWrapper>
  ) : (
    <AbsoluteLoadingWrapper>
      <Lottie options={defaultOptions} height={180} width={180} isClickToPauseDisabled />
    </AbsoluteLoadingWrapper>
  );
