import React from 'react';

import { RecordDetails } from '../RecordDetails';
import * as S from './styled';

import { ReactComponent as GreenCalendarRound } from './icons/green-calendar-round.svg';
import { ReactComponent as GreenDash } from './icons/green-dash.svg';
import { ReactComponent as GreenDot } from './icons/green-dot.svg';
import { ReactComponent as RedDot } from './icons/red-dot.svg';

const getCardAdornment = icon => {
  switch (icon) {
    case 'green-calendar-round':
      return (
        <S.Icon>
          <GreenCalendarRound />
        </S.Icon>
      );
    case 'green-dash':
      return (
        <S.Icon>
          <GreenDash />
        </S.Icon>
      );
    case 'green-dot':
      return (
        <S.Icon>
          <GreenDot />
        </S.Icon>
      );
    case 'red-dot':
      return (
        <S.Icon>
          <RedDot />
        </S.Icon>
      );
    default:
      return null;
  }
};

export const DetailsCard = ({ title, subtitle, direction, items, icon, marginTop, minWidth }) => (
  <S.Card $marginTop={marginTop} $minWidth={minWidth}>
    <S.Header $isAlignedCenter={!subtitle}>
      {getCardAdornment(icon)}
      <S.TitlesWrapper>
        <S.Title $isCentered={!subtitle}>{title}</S.Title>
        {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      </S.TitlesWrapper>
    </S.Header>
    <RecordDetails direction={direction} items={items} />
  </S.Card>
);
