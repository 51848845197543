import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuthContext } from 'context/AuthContext';

import { PageLoader } from './PageLoader';

export const DefaultDashboardRoute = ({ component, ...props }) => {
  const { me, isLoading } = useAuthContext();

  if (isLoading) {
    return <PageLoader />;
  }

  if (!me) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  switch (me.role) {
    case 'owner':
    case 'employee':
      return <Redirect to={{ pathname: '/panel/clients' }} />;
    default:
      return <Redirect to={{ pathname: '/panel' }} />;
  }
};
