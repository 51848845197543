import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button as BUIButton, SHAPE } from 'baseui/button';

import { ReactComponent as ArrowLeft } from './arrow-left.svg';

export const BackButton = withRouter(({ children, history, isDesktop }) => (
  <BUIButton
    shape={SHAPE.pill}
    startEnhancer={() => <ArrowLeft size={24} />}
    onClick={() => history.push('/login')}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => {
          return {
            ...$theme.typography.font300,
            color: $theme.colors.text,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: $theme.colors.border,
            marginBottom: isDesktop ? '5rem' : '2rem',

            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',

            backgroundColor: '#FFF',
            ':active': { backgroundColor: '#FFF' },
            ':hover': { backgroundColor: '#FFF' },
            ':focus': { backgroundColor: '#FFF' },
          };
        },
      },
    }}
  >
    Wróć
  </BUIButton>
));
