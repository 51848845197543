import React from 'react';

import * as S from './styled';

export const getTableOffset = ({ data: { pagination } }) => {
  let offset = null;
  if (pagination) {
    const { page, perPage, count } = pagination;
    const maxPage = count ? Math.ceil(count / perPage) : 0;
    offset = maxPage && page < maxPage ? page : null;
  }

  return offset;
};

export const transformDataIntoPages = (columns, data) =>
  data.map(({ key, content, attributes = {} }) => (
    <S.Row key={key} $isFaded={attributes.isFaded}>
      {content.map((cell, cellIndex) => {
        const width = columns[cellIndex].width;
        return (
          <S.Cell key={cellIndex} $width={width}>
            {cell}
          </S.Cell>
        );
      })}
    </S.Row>
  ));

export const checkIsTableLoading = (pageSWRs) => {
  let isLoading = !pageSWRs.length ? true : false;
  if (pageSWRs.length) {
    pageSWRs.forEach((page) => {
      if (page.isValidating) {
        isLoading = true;
      }
    });
  }

  return isLoading;
};
