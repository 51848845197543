import React from 'react';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider, DebugEngine } from 'styletron-react';
import { BaseProvider } from 'baseui';

import AuthProvider from 'context/AuthContext';

import { theme } from 'styles/theme';
import 'styles/globalStyle.css';

import { Router } from './Router';

const engine = new Styletron();
const debug = process.env.NODE_ENV === 'production' ? void 0 : new DebugEngine();

export const App = () => (
  <StyletronProvider value={engine} debug={debug} debugAfterHydration>
    <BaseProvider theme={theme}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </BaseProvider>
  </StyletronProvider>
);
