import React, { PureComponent } from 'react';

import * as C from 'components';
import * as MC from 'components/Modal/components';

import * as S from './styled';
import * as H from '../../helpers';

export class CardStep extends PureComponent {
  state = {
    isDone: false,
    isSaving: false,
    isMounted: false,
    isSkipMessageMounted: false,
    isSkipMessageActive: false,
    error: '',
  };

  componentDidMount() {
    setTimeout(() => this.setState({ isMounted: true }), 100);
  }

  onRead = async cardNumber => {
    this.setState({ isSaving: true, error: '' });

    const result = await H.addCardToClient(this.props.clientSlug, cardNumber);

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        setTimeout(() => this.setState({ isMounted: false }), 2000);
        setTimeout(() => this.props.nextStep(result.slug), 3000);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ isSaving: false, error });
  };

  showSkipMessage = () => {
    const mountSkipMessage = () =>
      setTimeout(() => this.setState({ isSkipMessageMounted: true }), 200);
    const activateSkipMessage = () =>
      setTimeout(() => this.setState({ isSkipMessageActive: true }, mountSkipMessage), 200);
    this.setState({ isMounted: false }, activateSkipMessage);
  };

  hideSkipMessage = () => {
    const mountCardStep = () => setTimeout(() => this.setState({ isMounted: true }), 200);
    const activateCardStep = () =>
      setTimeout(() => this.setState({ isSkipMessageActive: false }, mountCardStep), 200);
    this.setState({ isSkipMessageMounted: false }, activateCardStep);
  };

  render() {
    const {
      isDone,
      isSaving,
      isMounted,
      isSkipMessageMounted,
      isSkipMessageActive,
      error,
    } = this.state;
    const { skipStep } = this.props;

    if (isDone) {
      return (
        <MC.StepWrapper $isMounted={isMounted}>
          <MC.CenteredContent>
            <MC.Done title="Karta została dodana" />
          </MC.CenteredContent>
        </MC.StepWrapper>
      );
    }

    if (isSkipMessageActive) {
      return (
        <MC.StepWrapper $isMounted={isSkipMessageMounted}>
          <MC.CenteredContent>
            <S.ConfirmWrapper>
              <S.ConfirmMessage>
                Pominięcie tego kroku spowoduje dodanie użytkownika bez przypisanej karty
                członkowskiej. Czy na pewno chcesz to zrobić?
              </S.ConfirmMessage>
              <S.ConfirmButtons>
                <MC.Button onClick={this.hideSkipMessage}>Chcę dodać kartę</MC.Button>
                <MC.Button $isConfirm onClick={skipStep}>
                  Kontynuuj bez karty
                </MC.Button>
              </S.ConfirmButtons>
            </S.ConfirmWrapper>
          </MC.CenteredContent>
        </MC.StepWrapper>
      );
    }

    return (
      <MC.StepWrapper $isMounted={isMounted}>
        <MC.CenteredContent>
          <C.ScannerReader isActive={!isSaving && !isSkipMessageActive} onRead={this.onRead} />
          {error && <C.ErrorMessage error={error} />}
        </MC.CenteredContent>
        <MC.Footer>
          <div />
          <MC.Button disabled={isSaving} onClick={this.showSkipMessage}>
            Pomiń
          </MC.Button>
        </MC.Footer>
      </MC.StepWrapper>
    );
  }
}
