import React from 'react';
import { Field } from 'formik';
import { subYears } from 'date-fns';

import * as C from 'components';
import * as FS from '../styled';

const inputRootOverrides = {
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
};

export const ClientForm = ({ isSubmitting }) => (
  <>
    <FS.SectionTitle>Dane klienta</FS.SectionTitle>
    <FS.Row>
      <Field
        type="text"
        name="firstName"
        placeholder="Imię"
        disabled={isSubmitting}
        component={C.Input}
        RootOverrides={inputRootOverrides}
      />
      <Field
        type="text"
        name="lastName"
        placeholder="Nazwisko"
        disabled={isSubmitting}
        component={C.Input}
        RootOverrides={inputRootOverrides}
      />
    </FS.Row>
    <FS.Row>
      <Field
        type="email"
        name="email"
        placeholder="E-mail"
        disabled={isSubmitting}
        component={C.Input}
        RootOverrides={inputRootOverrides}
      />
      <Field
        type="text"
        name="phone"
        placeholder="Nr telefonu"
        disabled={isSubmitting}
        component={C.Input}
        RootOverrides={inputRootOverrides}
      />
    </FS.Row>
    <FS.Row>
      <Field
        name="birthdate"
        label="Data urodzenia"
        placeholder="DD/MM/RRRR"
        disabled={isSubmitting}
        component={C.Datepicker}
        RootOverrides={inputRootOverrides}
        component={C.Input}
        mask="99/99/9999"
      />
      <Field name="notes" placeholder="Notatki" disabled={isSubmitting} component={C.Textarea} />
    </FS.Row>
  </>
);
