import React from 'react';
import { styled } from 'baseui';

export const SectionTitle = styled('h3', ({ $theme }) => ({
  ...$theme.typography.font260,
  marginTop: 0,
  marginBottom: '1.25rem',
  lineHeight: 1,
  color: $theme.colors.text,
  alignSelf: 'flex-start',
  justifySelf: 'flex-start',
  textAlign: 'left',
}));

export const RowRoot = styled('div', ({ $marginBottom }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginTop: '1rem',
  marginBottom: typeof $marginBottom !== 'undefined' ? $marginBottom : '1.5rem',

  ':first-of-type': {
    marginTop: 0,
  },
}));

const RowItem = styled('div', ({ $theme, $itemsCount }) => ({
  marginLeft: '1.25rem',
  width: $itemsCount > 1 ? `calc((100% - ${$itemsCount - 1} * 1.25rem) / ${$itemsCount});` : '100%',

  ':first-of-type': {
    marginLeft: 0,
  },
}));

export const Row = ({ children, $marginBottom }) => {
  const itemsCount = Array.isArray(children) ? children.length : 1;

  return (
    <RowRoot $marginBottom={$marginBottom}>
      {Array.isArray(children) ? (
        <>
          {children[0] && <RowItem $itemsCount={itemsCount}>{children[0]}</RowItem>}
          {children[1] && <RowItem $itemsCount={itemsCount}>{children[1]}</RowItem>}
          {children[2] && <RowItem $itemsCount={itemsCount}>{children[2]}</RowItem>}
        </>
      ) : (
        <RowItem>{children}</RowItem>
      )}
    </RowRoot>
  );
};
