import React from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withWidth = WrappedComponent => {
  class WithWidth extends React.Component {
    state = { width: null };

    componentDidMount() {
      if (window) {
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
      }
    }

    componentWillUnmount() {
      if (window) {
        window.removeEventListener('resize', this.updateWidth);
      }
    }

    updateWidth = () => this.setState({ width: window.innerWidth });

    render() {
      return <WrappedComponent width={this.state.width} {...this.props} />;
    }
  }

  WithWidth.displayName = `WithWidth(${getDisplayName(WrappedComponent)})`;

  return WithWidth;
}
