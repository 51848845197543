import React from 'react';
import { styled } from 'baseui';

import * as C from 'components';

import { ReactComponent as ScanSvg } from './scan.svg';

export const ScanIcon = styled(ScanSvg, { marginRight: '1rem' });

export const Root = styled('div', {
  minWidth: '25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ErrorMessage = ({ error }) => (
  <C.ErrorMessage
    error={error}
    $WrapperOverrides={{
      marginTop: '0rem',
      marginBottom: '1.875rem',
      marginRight: '1.875rem',
      marginLeft: '1.875rem',
      width: 'calc(100% - 3.75rem)',
      boxSizing: 'border-box',
    }}
  />
);
