import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';

import * as C from 'components';
import * as MC from 'components/Modal/components';
import * as F from 'modals/forms';

import * as H from '../helpers';
import * as S from './styled';

export class EditClientModal extends PureComponent {
  state = {
    isDone: false,
    isMounted: true,
    error: '',
  };

  onClose = () => {
    this.props.onClose();
    this.setState({ isDone: false, isMounted: true, error: '' });
  };

  submitForm = async (values, { setErrors, setSubmitting }) => {
    const birthdate = H.getValidBirthdate(values.birthdate);
    if (values.birthdate && !birthdate) {
      this.setState({ error: 'Wprowadzono niepoprawną datę urodzenia.'});
      return setSubmitting(false);
    }
    this.setState({ error: '' });

    const result = await H.editClient({ ...values, birthdate });

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        setTimeout(() => this.setState({ isMounted: false }), 2000);
        setTimeout(() => {
          this.onClose();
          this.props.revalidate();
        }, 2250);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ error });
    setSubmitting(false);
  };

  render() {
    const { isOpen, client } = this.props;
    const { isDone, isMounted, error } = this.state;

    const initialValues = getInitialValues(client);

    return (
      <C.Modal isOpen={isOpen} onClose={this.onClose} title="Edytuj dane klienta">
        <S.Root>
          <MC.StepWrapper $isMounted={isMounted}>
            <MC.CenteredContent>
              {isDone ? (
                <MC.Done title="Dane klienta zostały zapisane" />
              ) : (
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, actions) =>
                    this.submitForm({ ...values, slug: client.slug }, actions)
                  }
                  render={({ isSubmitting, values }) => (
                    <Form autoComplete="off" style={{ flexGrow: 1 }}>
                      <F.ClientForm isSubmitting={isSubmitting} />
                      {error && <C.ErrorMessage error={error} />}
                      <MC.Footer>
                        <div />
                        <MC.Button
                          $isConfirm
                          disabled={isSubmitting || !values.firstName || !values.lastName}
                          type="submit"
                        >
                          Zapisz
                        </MC.Button>
                      </MC.Footer>
                    </Form>
                  )}
                />
              )}
            </MC.CenteredContent>
          </MC.StepWrapper>
        </S.Root>
      </C.Modal>
    );
  }
}

/**
 *    Helpers.
 */
const getInitialValues = (client) => {
  const birthdate = client.birthdate ? client.birthdate.split('-').reverse().join('/') : null;
  return {
    firstName: client.firstName || '',
    lastName: client.lastName || '',
    email: client.email || '',
    phone: client.phone || '',
    birthdate,
    notes: client.notes || '',
  };
};
