import { createTheme, lightThemePrimitives } from 'baseui';

const primaryFontFamily = '"Source Sans Pro", sans-serif';

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    primary: '#2DCA73',
    primaryFontFamily,
  },
  {
    primaryFontFamily,
    borderRadius: {
      sm: {
        borderTopLeftRadius: '7px',
        borderTopRightRadius: '7px',
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px',
      },
      lg: {
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      },
    },
    colors: {
      text: '#171725',
      text100: '#92929D',
      text200: '#A9B0C1',
      text300: '#8B96A8',
      link: '#0162ff',
      background: '#E5E5E5',
      background100: '#FAFAFB',
      background200: '#F5F6F7',
      background300: '#EFF1F4',
      border: '#F1F1F5',
      buttonPrimaryActive: '#204A32',
      buttonPrimaryHover: '#204A32',
      success: '#2DCA73',
      error: '#EA4335',
      warning: '#FFAE12',
      inactive: '#7E90A6',
      buttonSecondaryText: '#7E90A6',
    },
    typography: {
      font100: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        lineHeight: '1.25rem',
      },
      font160: {
        fontSize: '0.75rem',
        fontWeight: 600,
        lineHeight: '1.25rem',
      },
      font200: {
        fontSize: '0.875rem',
        fontWeight: 'normal',
        lineHeight: '1.25rem',
      },
      font260: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.25rem',
      },
      font300: {
        fontSize: '1rem',
        fontWeight: 'normal',
        lineHeight: '1.5rem',
      },
      font360: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
      },
      font400: {
        fontSize: '1.125rem',
        fontWeight: 'normal',
        lineHeight: '1.75rem',
      },
      font460: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: '1.75rem',
      },
      font500: {
        fontSize: '1.25rem',
        fontWeight: 'normal',
        lineHeight: '1.75rem',
      },
      font560: {
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: '1.75rem',
      },
      font600: {
        fontSize: '1.5rem',
        fontWeight: 'normal',
        lineHeight: '1.875rem',
      },
      font660: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: '1.875rem',
      },
    },
  },
);
