import React, { createRef, useState } from 'react';
import { Textarea as BUITextarea } from 'baseui/textarea';

import { withWidth } from 'hoc';

export const Textarea = withWidth(
  ({ field, form, width, RootOverrides, InputContainer, ...props }) => {
    const [isFocused, setFocus] = useState(false);
    const inputRef = createRef();

    const backgroundColor = props.backgroundColor || '#FFF';
    const isTopPlaceholderVisible = (props.placeholder && field.value) || isFocused;

    const rootOverrides = RootOverrides || {};
    const inputContainerOverrides = InputContainer || {};

    return (
      <BUITextarea
        {...field}
        {...props}
        onFocus={e => {
          setFocus(true);
          if (field.onFocus) field.onFocus(e);
        }}
        onBlur={e => {
          setFocus(false);
          if (field.onBlur) field.onBlur(e);
        }}
        inputRef={inputRef}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              backgroundColor,
              height: width < $theme.breakpoints.large ? '3.75rem' : '3.125rem',
              marginTop: '1rem',
              marginBottom: '1rem',
              marginRight: 'auto',
              marginLeft: 'auto',
              position: 'relative',
              ...rootOverrides,

              ':first-child': {
                marginTop: 0,
              },

              '::after': {
                content: isTopPlaceholderVisible ? `"${props.placeholder}"` : 'none',
                ...$theme.typography.font100,
                fontSize: '11px',
                color: isFocused ? $theme.colors.primary : $theme.colors.text100,
                backgroundColor,
                display: 'block',
                position: 'absolute',
                top: 0,
                left: '0.5rem',
                opacity: isTopPlaceholderVisible ? 1 : 0,
                overflow: 'hidden',
                padding: '0 0.5rem',
                transform: 'translateY(-50%)',
                transition: `${$theme.animation.timing100} all ${$theme.animation.easeOutCurve}`,
                pointerEvents: 'none',
              },
            }),
          },
          InputContainer: {
            style: ({ $theme }) => ({
              backgroundColor,
              borderWidth: '1px',
              borderColor: isFocused ? $theme.colors.primary : $theme.colors.border,
              ...$theme.borderRadius.sm,
              overflow: 'hidden',
              ...inputContainerOverrides,
            }),
          },
          Input: {
            style: ({ $theme }) => ({
              ...$theme.typography.font300,
              backgroundColor,
              color: props.disabled ? $theme.colors.text100 : $theme.colors.text,
            }),
          },
        }}
      />
    );
  },
);
