import React from 'react';

import * as S from './styled';

export const RecordDetails = ({ direction, items }) => (
  <>
    {direction === 'row' && items && !!items.length && (
      <S.RowItemsList>
        {items.map((item) => (
          <S.RowItem key={item.key || item.value}>
            <S.Label>{item.label}</S.Label>
            {item.to ? <S.Link to={item.to}>{item.value}</S.Link> : <S.Value>{item.value}</S.Value>}
          </S.RowItem>
        ))}
      </S.RowItemsList>
    )}
    {direction === 'column' && items && !!items.length && (
      <S.ColumnItemsList>
        {items.map((item) =>
          item.type === 'textarea' ? (
            <S.TextareaColumnItem key={item.key || item.value}>
              <S.Label>{item.label}</S.Label>
              <S.Value $whiteSpace="break-spaces">{item.value}</S.Value>
            </S.TextareaColumnItem>
          ) : (
            <S.ColumnItem key={item.key || item.value}>
              <S.Label>{item.label}</S.Label>
              {item.to ? (
                <S.Link to={item.to}>{item.value}</S.Link>
              ) : (
                <S.Value>{item.value}</S.Value>
              )}
            </S.ColumnItem>
          ),
        )}
      </S.ColumnItemsList>
    )}
  </>
);
