import React, { PureComponent } from 'react';

import * as C from 'components';
import * as MC from 'components/Modal/components';

import * as H from '../helpers';
import * as S from './styled';

export class CardModal extends PureComponent {
  state = {
    isDone: false,
    isSaving: false,
    isMounted: true,
    error: '',
  };

  onClose = () => {
    this.props.onClose();
    this.setState({ isDone: false, isSaving: false, isMounted: true, error: '' });
  };

  onRead = async cardNumber => {
    this.setState({ isSaving: true, error: '' });

    const result = await H.addCardToClient(this.props.clientSlug, cardNumber);

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        setTimeout(() => this.setState({ isMounted: false }), 2000);
        setTimeout(() => {
          this.onClose();
          this.props.revalidate();
        }, 2250);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ isSaving: false, error });
  };

  render() {
    const { isDone, isSaving, isMounted, error } = this.state;
    const { isOpen, cardNumber } = this.props;
    const title = cardNumber ? 'Szczegóły karty' : 'Dodaj kartę';

    return (
      <C.Modal isOpen={isOpen} onClose={this.onClose} title={title}>
        <S.Root>
          <MC.StepWrapper $isMounted={isMounted}>
            <MC.CenteredContent>
              {cardNumber ? (
                <C.CardDetails cardNumber={cardNumber} closeModal={this.onClose} />
              ) : (
                <div style={{ minHeight: '20rem' }}>
                  {isDone ? (
                    <MC.Done title="Karta została dodana" />
                  ) : (
                    <>
                      <C.ScannerReader isActive={!isSaving} onRead={this.onRead} />
                      {error && <C.ErrorMessage error={error} />}
                    </>
                  )}
                </div>
              )}
            </MC.CenteredContent>
          </MC.StepWrapper>
        </S.Root>
      </C.Modal>
    );
  }
}
