import React from 'react';
import { styled } from 'baseui';

import * as C from 'components';
import * as LC from './components';

const MobileWrapper = styled('div', {
  width: 'calc(100% - 2.5rem)',
  maxWidth: 'calc(100% - 2.5rem)',
  margin: '0 auto',
  paddingTop: '2rem',
});

const DesktopWrapper = styled('div', {
  width: '1170px',
  maxWidth: '1170px',
  margin: '0 auto',
  paddingTop: '3rem',
  paddingBottom: '3rem',
});

const Title = styled('h1', ({ $theme }) => ({
  ...$theme.typography.font360,
  fontFamily: $theme.primaryFontFamily,
  color: $theme.colors.text,
  margin: '0 auto 1rem',
}));

export const Layout = ({ title, children }) => (
  <>
    <LC.Navigation />
    <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
      <MobileWrapper>
        <Title>{title}</Title>
        {children}
      </MobileWrapper>
    </C.ResponsiveWrapper>
    <C.ResponsiveWrapper size="lg">
      <DesktopWrapper>
        <Title>{title}</Title>
        {children}
      </DesktopWrapper>
    </C.ResponsiveWrapper>
  </>
);
