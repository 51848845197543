import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

import * as S from '../styled';

export const getTableRow = (item, role, openEditModal, openRemoveModal) => {
  /* Prepare menu items. */
  const editAction = () => openEditModal(item);
  const removeAction = () => openRemoveModal(item);
  const menuItems = [{ label: 'Edytuj', action: editAction }];
  if (role === 'owner') {
    menuItems.push({ label: 'Usuń', action: removeAction });
  }

  const createdAt = item.createdAt
    ? format(new Date(item.createdAt), 'd MMM yyyy', { locale: plLocale })
    : null;

  return {
    key: item.slug,
    content: [
      <TC.TableRowTextField>{item.membershipTypeName}</TC.TableRowTextField>,
      <TC.TableRowTextField>{item.offerName}</TC.TableRowTextField>,
      <TC.TableRowTextField>
        <TC.TableRowNavLink to={`/panel/clients/${item.clientSlug}`}>{item.clientName}</TC.TableRowNavLink>
      </TC.TableRowTextField>,
      <S.MembershipExpiresField item={item} />,
      <TC.TableRowTextField>
        {`${item.price}zł`.replace('$', '').replace('.', ',')}
      </TC.TableRowTextField>,
      <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>,
      <TC.TableRowActionsButton items={menuItems} />,
    ],
  };
};
