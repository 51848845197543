import React, { PureComponent } from 'react';

import * as C from 'components';

import { ClientStep, CardStep, MembershipStep } from './steps';

export class CreateClientModal extends PureComponent {
  state = { step: 0, clientSlug: null, cardSlug: null };

  onClose = () => {
    this.props.onClose();
    setTimeout(() => this.setState({ step: 0, clientSlug: null, cardSlug: null }), 500);
  };

  clientNextStep = clientSlug => this.setState({ step: 1, clientSlug });

  cardNextStep = cardSlug => this.setState({ step: 2, cardSlug });

  membershipNextStep = () => this.setState({ step: 3 });

  getStepTitle = step => {
    switch (step) {
      case 0:
        return 'Dodaj klienta';
      case 1:
        return 'Dodaj kartę klienta';
      case 2:
        return 'Wybierz karnet klienta';
      default:
        return 'Dodaj klienta';
    }
  };

  render() {
    const { isOpen } = this.props;
    const { step, clientSlug, cardSlug } = this.state;

    return (
      <C.MultistepModal
        isOpen={isOpen}
        onClose={this.onClose}
        title={this.getStepTitle(step)}
        minHeight="42rem"
      >
        {step === 0 && <ClientStep nextStep={this.clientNextStep} />}
        {step === 1 && (
          <CardStep nextStep={this.cardNextStep} skipStep={this.onClose} clientSlug={clientSlug} />
        )}
        {step >= 2 && (
          <MembershipStep
            cardSlug={cardSlug}
            onClose={this.onClose}
            nextStep={this.membershipNextStep}
          />
        )}
      </C.MultistepModal>
    );
  }
}
