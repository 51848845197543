import React from 'react';
import { styled } from 'baseui';

const MembershipTypeWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const MembershipTypeName = styled('p', ({ $theme }) => ({
  ...$theme.typography.font260,
  lineHeight: 1,
  color: $theme.colors.text,
  margin: 0,
  width: '100%',
}));

const MembershipTypeValue = styled('p', ({ $theme }) => ({
  ...$theme.typography.font100,
  color: $theme.colors.text100,
  margin: 0,
  width: '100%',
}));

export const TableRowOfferDetails = ({ type, value }) => (
  <MembershipTypeWrapper>
    <MembershipTypeName>{type}</MembershipTypeName>
    {value && <MembershipTypeValue>{value}</MembershipTypeValue>}
  </MembershipTypeWrapper>
);
