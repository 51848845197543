import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'baseui';

export const AuthActionLink = styled(Link, ({ $theme }) => ({
  ...$theme.typography.font300,
  color: $theme.colors.primary,
  textDecoration: 'none',
}));

const Wrapper = styled('div', ({ $theme, position }) => ({
  position: 'absolute',
  top: position === 'top' ? 0 : 'inherit',
  bottom: position === 'bottom' ? 0 : 'inherit',
  left: 0,
  width: '100%',
  height: position === 'top' ? '3.5rem' : '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  backgroundColor: $theme.colors.background100,
}));

const Text = styled('p', ({ $theme }) => ({
  ...$theme.typography.font300,
  fontFamily: $theme.primaryFontFamily,
  color: $theme.colors.text,
  margin: 0,
}));

export const AuthAction = ({ children, position }) => (
  <Wrapper position={position}>
    <Text>{children}</Text>
  </Wrapper>
);
