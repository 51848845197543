import React from 'react';
import pl from 'date-fns/locale/pl';
import { Datepicker as BUIDatepicker } from 'baseui/datepicker';

import * as C from 'components';
import { withWidth } from 'hoc';

import './styles.css';

export const Datepicker = withWidth(({ field, form, width, RootOverrides, items, ...props }) => {
  /* Prepare selected value. */
  const value = form.values[field.name] ? [new Date(form.values[field.name])] : [];

  return (
    <div id="actevee-datepicker">
      <BUIDatepicker
        value={value}
        onChange={({ date }) => form.setFieldValue(field.name, date)}
        locale={pl}
        mask="99.99.9999"
        formatString="dd.MM.yyyy"
        minDate={props.minDate}
        maxDate={props.maxDate}
        overrides={{
          Day: {
            style: ({ $theme, $selected, $isHovered, $isHighlighted }) => ({
              color: $selected
                ? $theme.colors.white
                : $isHovered || $isHighlighted
                ? $theme.colors.white
                : $theme.colors.calendarForeground,
              ':after': {
                backgroundColor: $selected
                  ? $theme.colors.primary
                  : $isHovered || $isHighlighted
                  ? $theme.colors.primary
                  : 'transparent',
              },
            }),
          },
        }}
      />
      {props.placeholder && <span data-type="datepicker-placeholder">{props.placeholder}</span>}
      <C.HiddenInput type="text" name={field.name} value={value} />
    </div>
  );
});
