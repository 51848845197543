import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';

import { httpClient } from 'utils';

import * as C from 'components';
import * as MC from 'components/Modal/components';
import * as F from 'modals/forms';

import * as H from '../helpers';
import * as S from './styled';

const initialValues = { membershipTypeSlug: '', membershipOfferSlug: '', dateFrom: new Date() };

export class RenewModal extends PureComponent {
  state = {
    isDone: false,
    isMounted: true,
    membershipItems: [],
    offersItems: [],
    error: '',
  };

  async componentDidMount() {
    try {
      const {
        data: { items: membershipTypes },
      } = await httpClient('/membership-types');
      const filteredMT = membershipTypes.filter(mt => mt.isActive);
      const {
        data: { items: offers },
      } = await httpClient('/offers');
      const filteredOffers = offers.filter(offer => offer.isActive);

      const membershipItems = filteredMT.map(mt => ({ label: mt.name, value: mt.slug }));
      const offersItems = filteredOffers.map(o => ({
        label: o.name,
        value: o.slug,
        membershipTypeSlug: o.membershipTypeSlug,
      }));

      this.setState({ membershipItems, offersItems });
    } catch {
      this.setState({
        error:
          'Wystąpił błąd podczas pobierania listy dostępnych karnetów. Prosimy spróbować ponownie później.',
      });
    }
  }

  onClose = () => {
    this.props.onClose();

    this.setState({ isDone: false, isMounted: true, error: '' });
  };

  submitForm = async (values, { setErrors, setSubmitting }) => {
    this.setState({ error: '' });

    const result = await H.renewMembership(this.props.cardSlug, values);

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        setTimeout(() => this.onClose(), 2000);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ error });
    setSubmitting(false);
  };

  render() {
    const { isOpen } = this.props;
    const { isDone, isMounted, error, membershipItems, offersItems } = this.state;

    return (
      <C.Modal isOpen={isOpen} onClose={this.onClose} title="Odnów karnet">
        <S.Root>
          {isDone ? (
            <MC.StepWrapper $isMounted={isMounted}>
              <MC.CenteredContent>
                <MC.Done title="Karnet został odnowiony" />
              </MC.CenteredContent>
            </MC.StepWrapper>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={this.submitForm}
              render={({ isSubmitting, values }) => (
                <Form autoComplete="off">
                  <F.MembershipForm
                    isSubmitting={isSubmitting}
                    membershipItems={membershipItems}
                    offersItems={offersItems}
                    selectedMembershipType={values.membershipTypeSlug}
                  />
                  {error && <C.ErrorMessage error={error} />}
                  <MC.Footer>
                    <div />
                    <MC.Button
                      $isConfirm
                      disabled={isSubmitting || !values.membershipTypeSlug || !values.dateFrom}
                      type="submit"
                    >
                      Odnów karnet
                    </MC.Button>
                  </MC.Footer>
                </Form>
              )}
            />
          )}
        </S.Root>
      </C.Modal>
    );
  }
}
