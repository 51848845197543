import React from 'react';
import Lottie from 'react-lottie';
import { NavLink } from 'react-router-dom';
import { styled } from 'baseui';

import * as C from 'components';

import animationData from 'animations/dots-loader.json';

const LoadingWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '21rem',
  width: '25rem',
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Loader = () => (
  <LoadingWrapper>
    <Lottie options={defaultOptions} height={180} width={180} isClickToPauseDisabled />
  </LoadingWrapper>
);

export const ErrorMessage = ({ error }) => (
  <C.ErrorMessage
    error={error}
    $WrapperOverrides={{
      width: '100%',
      marginTop: 0,
      marginBottom: '1.875rem',
      marginRight: '1.875rem',
      marginLeft: '1.875rem',
    }}
  />
);

export const Root = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingLeft: '1.875rem',
  paddingRight: '1.875rem',
  paddingBottom: '0.25rem',
});

export const CardOwner = styled('p', ({ $theme }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font500,
}));

export const CardOwnerName = styled(NavLink, ({ $theme }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font560,
}));

export const NotesWrapper = styled('div', ({ $theme }) => ({
  padding: '0.75rem 1rem',
  border: `1px solid ${$theme.colors.border}`,
  borderRadius: '0.5rem',
  width: '100%',
  boxSizing: 'border-box',
  margin: '0.5rem auto 1.5rem'
}));

export const NotesTitle = styled('h3', ({ $theme }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font360,
  margin: '0 auto 0.25rem',
  width: '100%',
}));

export const NotesContent = styled('p', ({ $theme }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font200,
  margin: 0,
}));

export const HistoryNavLink = styled(NavLink, ({ $theme }) => ({
  ...$theme.typography.font260,
  color: $theme.colors.text,
  margin: '2rem 0 1rem',
}));
