import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

export const getMembershipsTableRow = (item) => {
  let label;
  let labelColor;
  if (item.isActive) {
    label = 'Aktywny';
    labelColor = 'success';
  } else {
    label = 'Nieaktywny';
    labelColor = 'inactive';
  }

  const createdAt = item.createdAt ? format(new Date(item.createdAt), 'd MMM yyyy', { locale: plLocale }) : null;
  const dateFrom = item.dateFrom ? format(new Date(item.dateFrom), 'd MMM yyyy', { locale: plLocale }) : null;
  const dateTo = item.dateTo ? format(new Date(item.dateTo), 'd MMM yyyy', { locale: plLocale }) : null; // TODO: Kiedyś dla karnetu bez jednej z dat moze wyświetlić głupotę

  return {
    key: item.slug,
    content: [
      <TC.TableRowTextField>{item.membershipTypeName}</TC.TableRowTextField>,
      <TC.TableRowTextField>{item.offerName}</TC.TableRowTextField>,
      <TC.TableRowTextField>{dateFrom}</TC.TableRowTextField>,
      <TC.TableRowTextField>{dateTo}</TC.TableRowTextField>,
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
      <TC.TableRowTextField>{`${item.price}zł`.replace('$', '').replace('.', ',')}</TC.TableRowTextField>,
      <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>,
    ],
  };
};
