import React from 'react';

import * as C from 'components';
import * as LC from './components';

const AuthAction = props => (
  <C.AuthAction {...props}>
    Nie masz konta? <C.AuthActionLink to="/register">Zarejestruj się</C.AuthActionLink>
  </C.AuthAction>
);

export const Login = () => (
  <>
    <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
      <C.LogoAndCenteredContentWrapper>
        <LC.LoginForm />
      </C.LogoAndCenteredContentWrapper>
    </C.ResponsiveWrapper>
    <C.ResponsiveWrapper size="lg">
      <C.AuthDesktopCard>
        <LC.LoginForm isDesktop />
      </C.AuthDesktopCard>
    </C.ResponsiveWrapper>
  </>
);
