import React from 'react';
import { render } from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { App } from './App';

function isReactInDevelomentMode(){ 
  return '_self' in React.createElement('div');
}

if (!isReactInDevelomentMode()) {
  Sentry.init({
    dsn: "https://37b57bc29efc497c977e0a17c9ef6fac@o543434.ingest.sentry.io/5664013",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

render(<App />, document.getElementById('root'));
