import React from 'react';

import * as S from './styled';

export const ContentBox = ({ isLoading, error, title, children }) => (
  <S.Root>
    {isLoading && <S.Loader />}
    {error && <S.ErrorMessage error={error} />}
    {!isLoading && !error && children}
  </S.Root>
);
