import React, { useState } from 'react';
import Barcode from 'react-barcode';

import * as C from './styled';
import { ScanButton } from './ScanButton';

export const MembershipCard = ({ cardNumber, companyName, clientName, expireDate }) => {
  const [isBarcodeVisible, setBardcodeVisibility] = useState(false);
  const toggleBardcode = () => setBardcodeVisibility(!isBarcodeVisible);

  return (
    <C.Wrapper>
      <C.CardWrapper $cardNumber={cardNumber} $companyName={companyName}>
        <ScanButton isOpen={isBarcodeVisible} onClick={toggleBardcode} />
        <C.Root $isVisible={!isBarcodeVisible}>
          <C.CompanyLogo $companyName={companyName} />
          <C.Details>
            <C.Name>{clientName}</C.Name>
            <C.Membership>
              <C.Label>Karnet wygasa:</C.Label>
              <C.ExpireDate>{expireDate}</C.ExpireDate>
            </C.Membership>
          </C.Details>
        </C.Root>
        <C.Root $isVisible={isBarcodeVisible}>
          <C.BarcodeTitle>Pokaż kod na recepcji</C.BarcodeTitle>
          <C.BarcodeWrapper>
            <Barcode
              width={2}
              height={50}
              fontSize={13}
              textMargin={0}
              background="transparent"
              lineColor="#FFF"
              value="00250"
            />
          </C.BarcodeWrapper>
        </C.Root>
      </C.CardWrapper>
    </C.Wrapper>
  );
};
