import { styled } from 'baseui';

export const Card = styled('div', ({ $theme, $marginTop, $minWidth }) => ({
  backgroundColor: '#FFF',
  ...$theme.borderRadius.sm,
  padding: '1rem 1.25rem',
  marginTop: $marginTop || '1.25rem',
  marginBottom: '1.25rem',
  marginRight: 'auto',
  marginLeft: 'auto',
  minWidth: $minWidth,

  ':first-child': {
    marginTop: 0,
  },
}));

export const TitlesWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

export const Title = styled('h2', ({ $theme, $isCentered }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font560,
  lineHeight: $isCentered ? '2.125rem' : 1,
  margin: 0,
  width: '100%',
}));

export const Subtitle = styled('p', ({ $theme }) => ({
  color: $theme.colors.text100,
  ...$theme.typography.font460,
  margin: 0,
  width: '100%',
}));

export const Header = styled('div', ({ $theme, $isAlignedCenter }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: $isAlignedCenter ? 'center' : 'flex-start',
  marginRight: '0.25rem',
}));

export const Icon = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2.125rem',
  height: '2.125rem',
  marginRight: '0.5rem',
});
