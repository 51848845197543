import React from 'react';
import { withStyle } from 'baseui';
import { Modal, ModalBody } from 'baseui/modal';

import * as C from './components';

export const Body = withStyle(ModalBody, ({ $theme }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font500,
  textAlign: 'center',
  marginTop: '2.75rem',
  marginBottom: 0,
  marginLeft: '4rem',
  marginRight: '4rem',
}));

export const ConfirmModal = ({
  isOpen,
  close,
  confirmLabel,
  confirmAction,
  cancelLabel,
  children,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={close}
    closeable={false}
    overrides={{
      Dialog: {
        style: ({ $theme }) => ({
          ...$theme.borderRadius.lg,
          overflow: 'hidden',
          maxWidth: '370px',
          paddingBottom: '6.375rem',
        }),
      },
      DialogContainer: {
        style: ({ $theme }) => ({
          backdropFilter: 'blur(2.5px)',
        }),
      },
    }}
  >
    <Body>{children}</Body>
    <C.Footer>
      <C.Button onClick={close}>{cancelLabel}</C.Button>
      <C.Button $isConfirm onClick={confirmAction}>
        {confirmLabel}
      </C.Button>
    </C.Footer>
  </Modal>
);
