import { NavLink } from 'react-router-dom';
import { styled } from 'baseui';

import { DesktopWrapper } from '../DesktopWrapper';

export const Wrapper = styled('nav', {
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 8px rgba(23, 23, 37, 0.05)',
  height: '5.625rem',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledDesktopWrapper = styled(DesktopWrapper, {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 !important',
});

export const StyledNavLink = styled(NavLink, ({ $theme, $pathname, to }) => ({
  ...$theme.typography.font200,
  fontWeight: $pathname === to ? 600 : 'normal',
  fontFamily: $theme.primaryFontFamily,
  color: $pathname === to ? $theme.colors.primary : $theme.colors.text100,
  textDecoration: 'none',
  marginLeft: '3.125rem',
  position: 'relative',

  ':first-child': {
    marginLeft: 0,
  },

  '::before': {
    content: $pathname === to ? "''" : '',
    height: '3px',
    width: '1.25rem',
    position: 'absolute',
    bottom: '-0.5rem',
    left: 0,
    backgroundColor: $theme.colors.primary,
    borderRadius: '26px',
    pointerEvents: 'none',
  },
}));
