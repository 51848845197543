import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

export const getCardsTableRow = (item, openConfirmModal, role) => {
  let label;
  let labelColor;
  switch (item.status) {
    case 'active': {
      label = 'Aktywna';
      labelColor = 'success';
      break;
    }
    case 'inactive': {
      label = 'Nieaktywna';
      labelColor = 'inactive';
      break;
    }
    case 'blocked': {
      label = 'Zablokowana';
      labelColor = 'error';
      break;
    }
    case 'lost': {
      label = 'Zgubiona';
      labelColor = 'warning';
      break;
    }
    default:
      label = '';
      labelColor = 'inactive'
  }
  const updatedAt = format(new Date(item.updatedAt), 'HH:mm d MMM yyyy', { locale: plLocale });

  const menuItems = [];
  switch (item.status) {
    case 'active': {
      menuItems.push({ label: 'Zablokuj', action: () => openConfirmModal(item.cardNumber, item.slug, { actionType: 'blocked' })});
      menuItems.push({ label: 'Oznacz jako zgubioną', action: () => openConfirmModal(item.cardNumber, item.slug, { actionType: 'lost' })});
      menuItems.push({ label: 'Usuń', action: () => openConfirmModal(item.cardNumber, item.slug, { actionType: 'remove' })});
      break;
    }
    case 'blocked':
    case 'inactive':
    case 'lost': {
      menuItems.push({ label: 'Aktywuj', action: () => openConfirmModal(item.cardNumber, item.slug, { actionType: 'active' })});
      menuItems.push({ label: 'Usuń', action: () => openConfirmModal(item.cardNumber, item.slug, { actionType: 'remove' })});
      break;
    }
    default:
  }

  const content = [
    <TC.TableRowTextField>{item.cardNumber}</TC.TableRowTextField>,
    <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
    <TC.TableRowTextField>{updatedAt}</TC.TableRowTextField>,
  ];

  if (role === 'owner') {
    content.push(<TC.TableRowActionsButton items={menuItems} />);
  }

  return {
    key: item.slug,
    content,
  };
};
