export const membershipsColumns = [
  {
    title: 'Nazwa karnetu',
    direction: null,
  },
  {
    title: 'Karnet od',
    width: '120px',
  },
  {
    title: 'Karnet do',
    direction: null,
    width: '120px',
  },
  {
    title: 'Status',
    direction: null,
    width: '120px',
  },
  {
    title: 'Pozostałe wejścia',
    direction: null,
    width: '140px',
  },
];