import { mutate } from 'swr';

import { httpClient } from 'utils';

export const toggleOfferAction = ({ slug, isActive }) =>
  httpClient
    .put(`/offers/${slug}`, { isActive: !isActive })
    .then(() => {
      mutate('/offers?perPage=50&page=1&isActive=true&sortColumn=name&search=');
      mutate('/offers?perPage=50&page=1&isActive=false&sortColumn=name&search=');
    })
    .catch((e) => console.log(e));
