import { styled } from 'baseui';

export const Root = styled('div', {
  overflow: 'hidden',
});

export const Wrapper = styled('div', {
  padding: '1rem',
  maxWidth: '14.375rem',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const CardWrapper = styled('div', {
  padding: '1rem',
  position: 'relative',
});

export const Laser = styled('span', ({ $theme, $isMounted }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '3px',
  width: $isMounted ? '100%' : '0%',
  borderRadius: '1.5px',
  backgroundColor: $theme.colors.primary,
  transform: 'translate(-50%, -100%)',
  boxShadow: '0px 5px 10px rgba(45, 202, 115, 0.9)',
  transition: `${$theme.animation.timing100} width ${$theme.animation.easeOutCurve} 500ms`,
}));

export const InputWrapper = styled('div', {
  display: 'flex',
  width: '1px',
  height: '1px',
  opacity: 0,
  overflow: 'hidden',
});

export const TextWrapper = styled('div', {
  padding: '1rem',
  textAlign: 'center',
});

export const Title = styled('h3', ({ $theme, $isMounted }) => ({
  ...$theme.typography.font260,
  color: $theme.colors.text,
  margin: 0,
  opacity: $isMounted ? 1 : 0,
  transition: `${$theme.animation.timing100} opacity ${$theme.animation.easeOutCurve} 500ms`,
}));

export const Subtitle = styled('p', ({ $theme, $isMounted }) => ({
  ...$theme.typography.font100,
  color: $theme.colors.text100,
  margin: 0,
  opacity: $isMounted ? 1 : 0,
  transition: `${$theme.animation.timing100} opacity ${$theme.animation.easeOutCurve} 500ms`,
}));
