import { styled } from 'baseui';

export const TableRowLabel = styled('p', ({ $theme, $color }) => {
  let color;
  switch ($color) {
    case 'success':
      color = $theme.colors.primary;
      break;
    case 'warning':
      color = $theme.colors.warning;
      break;
    case 'error':
      color = $theme.colors.error;
      break;
    default:
      color = $theme.colors.inactive;
      break;
  }

  return {
    ...$theme.typography.font160,
    color,
    margin: 0,
    position: 'relative',
    ...$theme.borderRadius.sm,
    overflow: 'hidden',
    lineHeight: '1.75rem',
    hieght: '1.75rem',
    padding: '0 0.625rem',
    whiteSpace: 'nowrap',
    cursor: 'default',

    ':before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      backgroundColor: color,
      opacity: 0.25,
    },
  };
});
