import React from 'react';

import * as C from 'components';

import { Title } from '../Title';
import * as H from './helpers';
import * as S from './styled';

export const ContentSection = ({ title, type, buttons, children }) => (
  <S.Root>
    <S.Header>
      <Title type={type}>{title}</Title>
      <S.HeaderActionsWrapper>{H.getParsedButtonsArray('primary', buttons)}</S.HeaderActionsWrapper>
    </S.Header>
    <C.Spacer size={1} />
    {children}
  </S.Root>
);
