import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as H from './helpers';

const columns = [
  {
    title: 'Tytuł',
    direction: null,
  },
  {
    title: 'Data',
    direction: null,
    width: '220px',
  },
];

export const CrudLogs = () => {
  const { isOpen, recordName, pageSWRs, closeConfirmModal, ...tableLayoutProps } = hooks.useTableLayoutData(
    'crud-logs',
    columns,
    H.getTableRow,
  );

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout isLoading={TH.checkIsTableLoading(pageSWRs)} title="Historia akcji" columns={columns} {...tableLayoutProps} />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};
