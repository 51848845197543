export const getRecordDetailsItems = (recordDetails) => {
  if (!recordDetails || !recordDetails.firstName || !recordDetails.lastName) {
    return [];
  }

  const birthdate = recordDetails.birthdate ? recordDetails.birthdate.split('-').reverse().join('.') : 'Brak';

  const result = [
    { label: 'Imię', value: recordDetails.firstName },
    { label: 'Nazwisko', value: recordDetails.lastName },
    { label: 'E-mail', value: recordDetails.email },
    { label: 'Numer telefonu', value: recordDetails.phone || 'Brak' },
    { label: 'Data urodzenia', value: birthdate },
  ];

  if (recordDetails.notes) {
    result.push({ label: 'Notatki', value: recordDetails.notes || 'Brak', type: 'textarea' });
  }

  return result;
};
