import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'open':
      return { isOpen: true, recordName: action.name, recordSlug: action.slug, additionalData: action.additionalData };
    case 'close':
      return { ...state, isOpen: false };
    default:
      throw new Error();
  }
}

const initialState = {
  isOpen: false,
  recordSlug: '',
  recordName: '',
  additionalData: {},
};

export function useConfirmModal() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    isOpen: state.isOpen,
    recordSlug: state.recordSlug,
    recordName: state.recordName,
    additionalData: state.additionalData,
    openConfirmModal: (name, slug, additionalData = {}) => dispatch({ type: 'open', name, slug, additionalData }),
    closeConfirmModal: () => dispatch({ type: 'close' }),
  };
}
