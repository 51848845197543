import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';
import * as S from '../styled';

export const getTableRow = (
  item,
  openConfirmModal,
  openEditModal,
  openCardModal,
  openRenewModal,
) => {
  const removeAction = () => openConfirmModal(`${item.firstName} ${item.lastName}`, item.slug);
  const editAction = () => openEditModal(item);
  const cardAction = () => openCardModal(item);
  const renewAction = () => openRenewModal(item);

  /* Prepare menu items. */
  const menuItems = [{ label: 'Edytuj', action: editAction }];
  if (item.cardSlug) {
    menuItems.push({ label: 'Odnów karnet', action: renewAction });
  } else {
    menuItems.unshift({ label: 'Usuń klienta', action: removeAction });
  }

  let label;
  let labelColor;
  switch (item.isActive) {
    case true:
      label = 'Zarejestrowany';
      labelColor = 'success';
      break;
    case false:
      label = 'Oczekuje';
      labelColor = 'warning';
      break;
    default:
      label = 'Bez konta';
      labelColor = 'inactive';
      break;
  }

  const createdAt = format(new Date(item.createdAt), 'd MMM yyyy', { locale: plLocale });

  return {
    key: item.slug,
    content: [
      <S.TableRowClientDetails {...item} />,
      <TC.TableRowButton onClick={cardAction} icon={item.cardNumber ? '' : 'plus'}>
        {item.cardNumber ? 'Szczegóły' : 'Dodaj kartę'}
      </TC.TableRowButton>,
      <TC.TableRowTextField>{item.phone}</TC.TableRowTextField>,
      <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>,
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
      <TC.TableRowActionsButton items={menuItems} />,
    ],
  };
};
