import React from 'react';
import { Button, KIND, SHAPE } from 'baseui/button';

import { ReactComponent as Plus } from './plus.svg';

export const TableRowButton = ({ children, icon, ...props }) => {
  let endEnhancer;
  switch (icon) {
    case 'plus':
      endEnhancer = () => <Plus />;
      break;
    default:
      endEnhancer = undefined;
      break;
  }

  return (
    <Button
      {...props}
      shape={SHAPE.pill}
      kind={KIND.tertiary}
      endEnhancer={endEnhancer}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            ...$theme.typography.font160,
            boxSizing: 'border-box',
            height: '1.75rem',
            lineHeight: 1,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: '0.625rem',
            paddingRight: '0.625rem',
            backgroundColor: 'transparent',
            color: $theme.colors.text100,
            borderColor: $theme.colors.border,
            borderWidth: '1px',
            borderStyle: 'solid',
          }),
        },
        EndEnhancer: { style: { marginLeft: '0.35rem' } },
      }}
    >
      {children}
    </Button>
  );
};
