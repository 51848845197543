import { NavLink } from 'react-router-dom';
import { styled } from 'baseui';

export const RowItemsList = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '1.5rem',
});

export const ColumnItemsList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const RowItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '0 1rem',

  ':first-child': {
    paddingLeft: 0,
  },
});

export const ColumnItem = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '1rem 0',
  borderTop: `1px solid ${$theme.colors.border}`,

  ':first-child': {
    borderTop: 'none',
  },

  ':last-child': {
    paddingBottom: 0,
  },
}));

export const TextareaColumnItem = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1rem 0',
  borderTop: `1px solid ${$theme.colors.border}`,

  ':first-child': {
    borderTop: 'none',
  },

  ':last-child': {
    paddingBottom: 0,
  },
}));

export const Label = styled('p', ({ $theme }) => ({
  color: $theme.colors.text100,
  ...$theme.typography.font200,
  margin: '0.25rem 0.75rem 0.25rem auto',
  width: '100%',
}));

export const Value = styled('p', ({ $theme, $whiteSpace }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font360,
  margin: 0,
  flexGrow: 1,
  whiteSpace: $whiteSpace || 'nowrap',
}));

export const Link = styled(NavLink, ({ $theme }) => ({
  color: $theme.colors.text,
  ...$theme.typography.font360,
  margin: 0,
  flexGrow: 1,
  whiteSpace: 'nowrap',
}));
