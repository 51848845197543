import React from 'react';
import { styled } from 'baseui';

import { ReactComponent as CardSuccessSvg } from './success-card.svg';

export const ConfirmWrapper = styled('div', { maxWidth: '24rem' });

export const ConfirmMessage = styled('p', ({ $theme }) => ({
  width: '100%',
  textAlign: 'center',
  ...$theme.typography.font400,
  color: $theme.colors.text,
  margin: 0,
}));

/**
 * MembershipStep - Card success.
 */
const TextWrapper = styled('div', {
  padding: '0 1rem 1rem',
  textAlign: 'center',
});

const LastStepTitle = styled('h3', ({ $theme, $isMounted }) => ({
  ...$theme.typography.font260,
  color: $theme.colors.text,
  margin: 0,
}));

const LastStepSubtitle = styled('p', ({ $theme, $isMounted }) => ({
  ...$theme.typography.font100,
  color: $theme.colors.text100,
  margin: 0,
}));

const StyledCard = styled(CardSuccessSvg, { marginBottom: '1rem' });

const CardSuccessRoot = styled('div', { maxWidth: '14.5rem', margin: '-1rem auto 1rem' });

export const CardSuccess = () => (
  <CardSuccessRoot>
    <StyledCard />
    <TextWrapper>
      <LastStepTitle>Udało się!</LastStepTitle>
      <LastStepSubtitle>
        Karta została pomyślnie zeskanowana i&nbsp;przypisana do klienta
      </LastStepSubtitle>
    </TextWrapper>
  </CardSuccessRoot>
);

export const ConfirmButtons = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2rem',
});
