export const statefulMenu = {
  List: { style: { outline: 'none' } },
  Option: {
    style: ({ $theme }) => ({
      ...$theme.typography.font160,
      color: $theme.colors.text100,
      ':hover': {
        color: $theme.colors.text,
        backgroundColor: 'transparent',
      },
    }),
  },
};

export const statefulPopover = {
  Body: { style: ({ $theme }) => ({ ...$theme.borderRadius.sm, overflow: 'hidden auto', maxHeight: '100vh' }) },
  Inner: { style: ({ $theme }) => ({ ...$theme.borderRadius.sm, overflow: 'hidden' }) },
};

export const getBUIInputOverrides = ({ width, disabled, backgroundColor, rootOverrides, placeholder }) => ({
  Root: {
    style: ({ $theme }) => ({
      backgroundColor,
      height: width < $theme.breakpoints.large ? '3.75rem' : '3.125rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      marginRight: 'auto',
      marginLeft: 'auto',
      position: 'relative',
      cursor: 'pointer',
      pointerEvents: 'none',
      ...rootOverrides,

      ':first-child': {
        marginTop: 0,
      },

      '::after': {
        content: `"${placeholder}"`,
        ...$theme.typography.font100,
        fontSize: '11px',
        color: $theme.colors.text100,
        backgroundColor,
        display: 'block',
        position: 'absolute',
        top: 0,
        left: '0.5rem',
        opacity: 1,
        overflow: 'hidden',
        padding: '0 0.5rem',
        transform: 'translateY(-50%)',
        transition: `${$theme.animation.timing100} all ${$theme.animation.easeOutCurve}`,
        pointerEvents: 'none',
      },
    }),
  },
  InputContainer: {
    style: ({ $theme }) => ({
      backgroundColor,
      borderWidth: '1px',
      borderColor: $theme.colors.border,
      ...$theme.borderRadius.sm,
      overflow: 'hidden',
    }),
  },
  Input: {
    style: ({ $theme }) => ({
      ...$theme.typography.font300,
      backgroundColor,
      color: disabled ? $theme.colors.text100 : $theme.colors.text,
    }),
  },
  EndEnhancer: {
    style: ({ $theme }) => {
      return {
        backgroundColor,
        position: 'absolute',
        right: '1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: 0,
      };
    },
  },
});
