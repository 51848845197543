import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

export const getTableRow = (item) => {
  const createdAt = format(new Date(item.createdAt), 'HH:mm d MMM yyyy', { locale: plLocale });

  return {
    key: item.slug,
    content: [<TC.TableRowTextField>{item.title}</TC.TableRowTextField>, <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>],
  };
};
