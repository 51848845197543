import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';

export const getMembershipRecordRow = (membership, isEntracesVisible) => {
  let label;
  let labelColor;
  if (membership.isFulfilled) {
    label = 'Zakończony';
    labelColor = 'inactive';
  } else if (membership.isActive) {
    label = 'Aktywny';
    labelColor = 'success';
  } else {
    label = 'Nadchodzący';
    labelColor = 'inactive';
  }

  const dateFrom = membership.dateFrom ? format(new Date(membership.dateFrom), 'd MMM yyyy', { locale: plLocale }) : null;
  const dateTo = membership.dateTo ? format(new Date(membership.dateTo), 'd MMM yyyy', { locale: plLocale }) : null; // TODO: Kiedyś dla karnetu bez jednej z dat moze wyświetlić głupotę

  const result = {
    key: membership.membershipSlug,
    attributes: { isFaded: !membership.isActive && membership.isFulfilled },
    content: [
      <TC.TableRowTextField>{membership.membershipTypeName}</TC.TableRowTextField>,
      <TC.TableRowTextField>{dateFrom}</TC.TableRowTextField>,
      <TC.TableRowTextField>{dateTo}</TC.TableRowTextField>,
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
    ],
  };

  if (isEntracesVisible) {
    result.content.push(<TC.TableRowTextField>{membership.entrancesLeft}</TC.TableRowTextField>);
  }

  return result;
};
