import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'open-edit':
      return { ...initialState, isEditModalOpen: true, membership: action.membership };
    case 'close-edit':
      return initialState;
    case 'open-remove':
      return { ...initialState, isRemoveModalOpen: true, membership: action.membership };
    case 'close-remove':
      return initialState;
    default:
      throw new Error();
  }
}

const initialState = {
  isEditModalOpen: false,
  isRemoveModalOpen: false,
  membership: null,
};

export function useMembershipState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    membership: state.membership,
    isEditModalOpen: state.isEditModalOpen,
    openEditModal: (membership) => dispatch({ type: 'open-edit', membership }),
    closeEditModal: () => dispatch({ type: 'close-edit' }),
    isRemoveModalOpen: state.isRemoveModalOpen,
    openRemoveModal: (membership) => dispatch({ type: 'open-remove', membership }),
    closeRemoveModal: () => dispatch({ type: 'close-remove' }),
  };
}
