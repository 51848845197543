import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as H from './helpers';

const columns = [
  {
    title: 'Akcja',
    direction: null,
  },
  {
    title: 'Data',
    width: '200px',
  },
];

const getEmployeeName = (recordDetails) => {
  if (recordDetails && recordDetails.firstName && recordDetails.lastName) {
    return `${recordDetails.firstName} ${recordDetails.lastName}`;
  }

  return '';
};

export const EmployeeDetails = ({
  match: {
    params: { slug },
  },
}) => {
  /* Connect to proper state hooks for this page. */
  const pageState = hooks.useRecordDetailsPageState(`employees/${slug}`);
  const { pageSWRs, pages, ...tableLayoutProps } = hooks.useTableLayoutData('crud-logs', columns, H.getTableRow, {
    isSearchExculded: true,
    filters: { employeeSlug: slug },
  });

  /* Helper for revalidating employees list. */
  // eslint-disable-next-line no-unused-vars
  const revalidate = () => pageSWRs.forEach((page) => page.revalidate());

  const employeeName = getEmployeeName(pageState.recordDetails);
  const title = employeeName || '';
  const recordDetailsItems = H.getRecordDetailsItems(pageState.recordDetails);
  const contentBoxProps = { isLoading: pageState.isLoading, error: pageState.error };

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.ContentBox {...contentBoxProps}>
          <C.ContentSection type="primary" title={title}>
            <div style={{ maxWidth: '24rem' }}>
              <C.RecordDetails direction="column" items={recordDetailsItems} />
            </div>
          </C.ContentSection>
          <C.ContentSection type="secondary" title="Historia akcji">
            <C.TableLayout
              isInline
              isLoading={TH.checkIsTableLoading(pageSWRs)}
              columns={columns}
              pages={pages}
              emptyListSubtitle="Brak wykonanych akcji"
              {...tableLayoutProps}
            />
          </C.ContentSection>
        </C.ContentBox>
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};
