import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import { StatefulTooltip, TRIGGER_TYPE } from 'baseui/tooltip';

import * as TC from 'components/TableLayout/components';

export const MembershipExpiresField = ({ item }) => {
  let label;
  let labelColor;
  if (item.isActive) {
    label = 'Aktywny';
    labelColor = 'success';
  } else {
    label = 'Nieaktywny';
    labelColor = 'inactive';
  }

  const dateFrom = item.dateFrom
    ? format(new Date(item.dateFrom), 'd MMMM yyyy', { locale: plLocale })
    : null;
  const dateTo = item.dateTo
    ? format(new Date(item.dateTo), 'd MMMM yyyy', { locale: plLocale })
    : null; // TODO: Kiedyś dla karnetu bez jednej z dat moze wyświetlić głupotę

  return (
    <StatefulTooltip
      content={() => `Karnet ważny od ${dateFrom} do ${dateTo}`}
      triggerType={TRIGGER_TYPE.hover}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            ...$theme.borderRadius.sm,
            backgroundColor: $theme.colors.text,
            overflow: 'hidden',
          }),
        },
      }}
    >
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>
    </StatefulTooltip>
  );
};
