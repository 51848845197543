import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import { useAuthContext } from 'context';

import * as M from '../Clients/modals';
import * as H from './helpers';
import * as CDC from './components';

const getClientName = (recordDetails) => {
  if (recordDetails && recordDetails.firstName && recordDetails.lastName) {
    return `${recordDetails.firstName} ${recordDetails.lastName}`;
  }

  return '';
};

export const ClientDetails = ({
  match: {
    params: { slug },
  },
}) => {
  const { me } = useAuthContext();

  /* Connect to proper state hooks for this page. */
  const pageState = hooks.useRecordDetailsPageState(`clients/${slug}`);

  const client = pageState.recordDetails || {};
  const detailsButtons = [{ label: 'Edytuj dane', action: () => pageState.openModal('edit') }];
  const membershipsButtons = client.cardSlug ? [{ label: 'Odnów karnet', action: () => pageState.openModal('renew') }] : [];
  const cardsButtons = [{ label: 'Dodaj nową kartę', action: () => pageState.openModal('add-card') }];
  const clientName = getClientName(pageState.recordDetails);
  const title = clientName || '';
  const recordDetailsItems = H.getRecordDetailsItems(pageState.recordDetails);
  const contentBoxProps = { isLoading: pageState.isLoading, error: pageState.error };

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>Widok mobilny w przygotowaniu...</C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.ContentBox {...contentBoxProps}>
          <C.ContentSection type="primary" title={title} buttons={detailsButtons}>
            <div style={{ maxWidth: '24rem' }}>
              <C.RecordDetails direction="column" items={recordDetailsItems} />
            </div>
          </C.ContentSection>
          <C.ContentSection type="secondary" title="Lista kart" buttons={cardsButtons}>
            <CDC.ClientDetailsCardsList
              clientSlug={slug}
              isCardModalOpen={pageState.modalType === 'add-card' && pageState.isModalOpen}
              closeCardModal={pageState.closeModal}
            />
          </C.ContentSection>
          <C.ContentSection type="secondary" title="Historia karnetów" buttons={membershipsButtons}>
            <CDC.ClientDetailsMembershipsList clientSlug={slug} />
          </C.ContentSection>
          {me.role === 'owner' && (
            <C.ContentSection type="secondary" title="Historia wizyt">
              <CDC.ClientDetailsVisitsList clientSlug={slug} />
            </C.ContentSection>
          )}
        </C.ContentBox>
      </C.ResponsiveWrapper>
      <M.EditClientModal
        isOpen={pageState.modalType === 'edit' && pageState.isModalOpen}
        onClose={pageState.closeModal}
        client={client}
        revalidate={pageState.revalidate}
      />
      <M.RenewModal
        isOpen={pageState.modalType === 'renew' && pageState.isModalOpen}
        onClose={pageState.closeModal}
        cardSlug={client.cardSlug}
        clientSlug={client.slug}
        revalidate={() => {}}
      />
    </C.Layout>
  );
};
