import { useReducer } from 'react';
import { mutate } from 'swr';

import { swr } from 'utils';

const initialState = {
  isModalOpen: false,
  modalType: null,
  modalData: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'open-modal':
      return {
        ...initialState,
        isModalOpen: true,
        modalType: action.modalType,
        modalData: action.modalData,
      };
    case 'close-modal':
      return { ...state, isModalOpen: false };
    default:
      throw new Error();
  }
}

export const useRecordDetailsPageState = url => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, error, isValidating } = swr(url);

  const recordDetails = Object.keys(data).length ? data : null;

  return {
    /* Data. */
    ...state,
    recordDetails,
    error: !isValidating && error ? error : null,
    isLoading: !recordDetails && isValidating,
    isRevalidating: !!(recordDetails && isValidating),
    /* Actions. */
    openModal: (modalType, modalData = null) =>
      dispatch({ type: 'open-modal', modalType, modalData }),
    closeModal: () => dispatch({ type: 'close-modal' }),
    revalidate: () => mutate(url),
  };
};
