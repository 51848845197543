import { withWidth } from 'hoc';
import { theme } from 'styles';

const getSizeValue = (children, width, size) => {
  const { breakpoints } = theme;

  switch (size) {
    case 'xs':
      return width < breakpoints.small ? children : null;
    case 'sm':
      return width >= breakpoints.small && width < breakpoints.medium ? children : null;
    case 'md':
      return width >= breakpoints.medium && width < breakpoints.large ? children : null;
    case 'lg':
      return width >= breakpoints.large ? children : null;
    default:
      return null;
  }
};

export const ResponsiveWrapper = withWidth(({ children, width, size }) => {
  if (!width) {
    return null;
  }

  if (typeof size === 'string') {
    return getSizeValue(children, width, size);
  }

  let result = null;
  if (Array.isArray(size)) {
    size.forEach(s => {
      const value = getSizeValue(children, width, s);
      if (!result && value) {
        result = value;
      }
    });
  }

  return result;
});
