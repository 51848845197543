import React from 'react';
import { KIND } from 'baseui/button';

import * as C from 'components';

export const getParsedButtonsArray = (type, buttons) =>
  Array.isArray(buttons)
    ? buttons.map((button, index) => {
        return (
          <C.PillButton
            key={`${index}-${button.label}`}
            onClick={button.action}
            icon={button.icon}
            kind={type === 'secondary' ? KIND.secondary : undefined}
            BaseButtonOverrides={{
              marginRight: buttons.length - index > 1 ? '1.875rem' : 0,
            }}
          >
            {button.label}
          </C.PillButton>
        );
      })
    : [];
