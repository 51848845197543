import React from 'react';
import Lottie from 'react-lottie';
import { styled } from 'baseui';

import * as C from 'components';

import animationData from 'animations/dots-loader.json';

const LoadingWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '21rem',
  width: '25rem',
  margin: '0 auto',
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Loader = () => (
  <LoadingWrapper>
    <Lottie options={defaultOptions} height={180} width={180} isClickToPauseDisabled />
  </LoadingWrapper>
);

export const ErrorMessage = ({ error }) => (
  <C.ErrorMessage
    error={error}
    $WrapperOverrides={{
      width: '100%',
      marginTop: 0,
      marginBottom: '1.875rem',
      marginRight: '1.875rem',
      marginLeft: '1.875rem',
    }}
  />
);

export const Root = styled('div', ({ $theme }) => ({
  padding: '2.5rem 5.375rem',
  backgroundColor: '#FFF',
  ...$theme.borderRadius.lg,
}));
