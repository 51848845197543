import React from 'react';
import { styled } from 'baseui';

const OfferWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const OfferName = styled('p', ({ $theme }) => ({
  ...$theme.typography.font260,
  lineHeight: 1,
  color: $theme.colors.text,
  margin: 0,
  width: '100%',
}));

const OfferMembershipType = styled('p', ({ $theme }) => ({
  ...$theme.typography.font100,
  color: $theme.colors.text100,
  margin: 0,
  width: '100%',
}));

export const TableRowOfferDetails = ({ name, membershipTypeName }) => (
  <OfferWrapper>
    <OfferName>{name}</OfferName>
    {membershipTypeName && <OfferMembershipType>{membershipTypeName}</OfferMembershipType>}
  </OfferWrapper>
);
