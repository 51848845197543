import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as H from './helpers';

const columns = [
  {
    title: 'Nazwisko',
    direction: null,
  },
  {
    title: 'Nr telefonu',
    direction: null,
    width: '160px',
  },
  {
    title: 'Status',
    direction: null,
    width: '160px',
  },
  {
    title: 'Data dołączenia',
    direction: null,
    width: '160px',
  },
];

export const Employees = () => {
  const {
    isOpen,
    recordName,
    pageSWRs,
    closeConfirmModal,
    ...tableLayoutProps
  } = hooks.useTableLayoutData('employees', columns, H.getTableRow);

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
        Widok mobilny w przygotowaniu...
      </C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout
          isLoading={TH.checkIsTableLoading(pageSWRs)}
          title="Pracownicy"
          columns={columns}
          {...tableLayoutProps}
        />
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};
