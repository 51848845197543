import React from 'react';
import { format } from 'date-fns';
import plLocale from 'date-fns/locale/pl';

import * as TC from 'components/TableLayout/components';
import * as S from '../styled';

export const getTableRow = item => {
  let label;
  let labelColor;
  if (item.isActive) {
    label = 'Konto aktywne';
    labelColor = 'success';
  } else {
    label = 'Konto nieaktywne';
    labelColor = 'inactive';
  }

  const createdAt = format(new Date(item.createdAt), 'd MMM yyyy', { locale: plLocale });

  return {
    key: item.slug,
    content: [
      <S.TableRowEmployeeDetails {...item} />,
      <TC.TableRowTextField>{item.phone}</TC.TableRowTextField>,
      <TC.TableRowLabel $color={labelColor}>{label}</TC.TableRowLabel>,
      <TC.TableRowTextField>{createdAt}</TC.TableRowTextField>,
    ],
  };
};
