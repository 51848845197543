export const membershipsColumns = [
  {
    title: 'Nazwa karnetu',
    direction: null,
  },
  {
    title: 'Promocja',
    direction: null,
    width: '180px',
  },
  {
    title: 'Karnet od',
    width: '120px',
  },
  {
    title: 'Karnet do',
    direction: 'DESC',
    width: '120px',
  },
  {
    title: 'Status',
    direction: null,
    width: '120px',
  },
  {
    title: 'Cena',
    direction: null,
    width: '100px',
  },
  {
    title: 'Data zakupu',
    width: '120px',
  },
];
