import { httpClient } from 'utils';

export const startVisit = async ({ cardNumber, closeModal, setIsWaiting, setError }) => {
  setIsWaiting(true);
  let membershipSlug;
  try {
    const { data } = await httpClient.get(`/cards/${cardNumber}/status`);
    membershipSlug = data.memberships.reduce((prev, curr) => curr.isActive ? curr.membershipSlug : prev, null);
  } catch (e) {
    setIsWaiting(false);
    setError('Nie udało się pobrać informacji o karcie.');
    return;
  }

  if (!membershipSlug) {
    setIsWaiting(false);
    setError('Nie udało się znaleźć aktywnego karnetu.');
    return;
  }

  try {
    await httpClient.post('/visits', { membershipSlug });
  } catch (e) {
    setIsWaiting(false);
    setError('Nie udało się rozpocząć wizyty.');
    return;
  }
  
  closeModal();
};

export const endVisit = ({ closeModal, setIsWaiting, setError, visitSlug }) => {
  setIsWaiting(true);
  httpClient
    .put(`/visits/${visitSlug}`, { isVisitFinished: true })
    .then(closeModal)
    .catch(() => {
      setIsWaiting(false);
      setError('Nie udało się zakończyć wizyty.');
    });
};
