import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'baseui';

import * as C from 'components';

const StyledLink = styled(Link, ({ $theme, $isDesktop }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.text100,
  textDecoration: 'none',
  width: 'calc(50% - 2rem)',
  flexBasis: 'calc(50% - 2rem)',
  marginBottom: $isDesktop ? '2rem' : 0,
}));

const StyledButton = styled(C.Button, ({ $theme, $isDesktop }) => ({
  width: $isDesktop ? '100%' : 'calc(50% + 1rem)',
  flexBasis: $isDesktop ? '100%' : 'calc(50% + 1rem)',
}));

const Wrapper = styled('div', ({ $theme, $isDesktop }) => ({
  display: 'flex',
  flexDirection: $isDesktop ? 'column' : 'row',
  justifyContent: 'space-between',
  alignItems: $isDesktop ? 'flex-start' : 'center',
  margin: '1rem auto',
}));

export const LoginActions = ({ onClick, isDesktop, isLoading }) => {
  return (
    <Wrapper $isDesktop={isDesktop}>
      <StyledLink $isDesktop={isDesktop} to="/reset-password">
        Nie pamiętasz hasła?
      </StyledLink>
      <StyledButton $isDesktop={isDesktop} isLoading={isLoading} type="submit">
        Zaloguj się
      </StyledButton>
    </Wrapper>
  );
};
