import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { SortableHeadCell } from 'baseui/table';

import * as C from 'components';
import * as TC from './components';
import * as S from './styled';

export const TableLayout = ({
  isLoading,
  isInline,
  title,
  buttonAction,
  buttonLabel,
  columns,
  pages,
  search,
  setSearch,
  loadMore,
  isEmpty,
  isReachingEnd,
  emptyListTitle,
  emptyListSubtitle,
}) => (
  <TC.TableRoot isInline={isInline}>
    {!isInline && (
      <S.Header>
        <S.HeaderRow>
          <S.Title>{title}</S.Title>
          <div>
            {buttonAction && buttonLabel && (
              <C.PillButton onClick={buttonAction}>{buttonLabel}</C.PillButton>
            )}
          </div>
        </S.HeaderRow>
        <S.HeaderRow>
          <div />
          <S.SearchWrapper>
            {setSearch && (
              <C.PillInput
                $width="20rem"
                icon="search"
                placeholder="Szukaj"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            )}
          </S.SearchWrapper>
        </S.HeaderRow>
      </S.Header>
    )}
    <S.Table>
      <S.Head>
        {columns.map(({ width, ...column }) => (
          <SortableHeadCell
            key={column.title}
            {...column}
            overrides={{
              HeadCell: {
                style: {
                  borderWidth: 0,
                  width,
                  flex: width ? `0 ${width}` : '1',
                  boxSizing: 'border-box',
                },
              },
              SortableLabel: {
                style: ({ $theme }) => ({
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  ...$theme.typography.font260,
                  color: $theme.colors.text100,
                }),
              },
              SortDirectionIcon: {
                style: { marginLeft: '0.25rem' },
              },
            }}
          />
        ))}
      </S.Head>
      <S.Body $isLoading={isLoading}>
        <InfiniteScroll loadMore={loadMore} hasMore={!isReachingEnd} loader={null}>
          {pages}
        </InfiniteScroll>
        {!pages.length && (
          <>
            {isLoading ? (
              <TC.TableLoadingListIndicator />
            ) : (
              <TC.TableEmptyListIndicator
                title={emptyListTitle}
                subtitle={emptyListSubtitle}
                isListEmpty
              />
            )}
          </>
        )}
        {!isLoading && isEmpty && (
          <TC.TableEmptyListIndicator
            title={emptyListTitle}
            subtitle={emptyListSubtitle}
            isListEmpty
          />
        )}
        {!!pages.length && isLoading && <TC.TableLoadingListIndicator />}
      </S.Body>
    </S.Table>
  </TC.TableRoot>
);
