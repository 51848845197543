import { styled } from 'baseui';

export const Root = styled('div', ({ $theme }) => ({
  marginTop: '8rem',

  ':first-child': {
    marginTop: 0,
  },
}));

export const Header = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const HeaderActionsWrapper = styled('div', ({ $theme }) => ({
  backgroundColor: 'transparent',
}));
