import { useReducer } from 'react';

const initialState = { isLoading: false, cardDetails: null, error: null };

function reducer(state, action) {
  switch (action.type) {
    case 'fetch':
      return { ...initialState, isLoading: true };
    case 'success':
      return { ...initialState, cardDetails: action.cardDetails };
    case 'error':
      return { ...initialState, error: action.error };
    default:
      throw new Error();
  }
}

export function useCardDetailsState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    isLoading: state.isLoading,
    cardDetails: state.cardDetails,
    error: state.error,
    fetchCardDetails: () => dispatch({ type: 'fetch' }),
    receiveSuccess: cardDetails => dispatch({ type: 'success', cardDetails }),
    receiveError: error => dispatch({ type: 'error', error }),
  };
}
