import React from 'react';
import { styled } from 'baseui';

import { Logo } from 'components/Logo';

const LogoWrapper = styled('div', {
  alignSelf: 'flex-start',
  width: '100%',
  flexBasis: '100%',
});

export const LogoAndCenteredContentWrapper = ({ children, hasFooterAction }) => {
  const StyledLogoAndCenteredContentWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: '#FFF',
  });

  const ContentWrapper = styled('div', {
    alignSelf: 'baseline',
    width: '100%',
    flexBasis: '100%',
    minHeight: '100%',
    boxSizing: 'border-box',
    paddingBottom: hasFooterAction ? '7.375rem' : 0,
  });

  return (
    <StyledLogoAndCenteredContentWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </StyledLogoAndCenteredContentWrapper>
  );
};
