import React from 'react';
import { Button as BUIButton, SHAPE } from 'baseui/button';

import { ReactComponent as MenuSvg } from './menu.svg';

export const MenuButton = React.forwardRef(({ children, ...props }, ref) => (
  <BUIButton
    {...props}
    ref={ref}
    shape={SHAPE.round}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => {
          return {
            backgroundColor: 'transparent',
            color: $theme.colors.text,
            position: 'absolute',
            top: '50%',
            right: '0.75rem',
            transform: 'translateY(-50%)',

            ':active': { backgroundColor: 'transparent' },
            ':hover': { backgroundColor: 'transparent' },
            ':focus': { backgroundColor: 'transparent' },
          };
        },
      },
    }}
  >
    <MenuSvg />
  </BUIButton>
));
