import React, { useState } from 'react';

import * as C from 'components';

import * as S from './styled';

export const CheckCardStatus = () => {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState(null);

  const onRead = value => setCardNumber(value);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setCardNumber(null);
    setError(null);
  };

  return (
    <>
      <C.PillButton
        onClick={openModal}
        BaseButtonOverrides={{
          paddingLeft: '1.25rem',
          paddingRight: '1.25rem',
          marginRight: '1.875rem',
        }}
      >
        <S.ScanIcon />
        Skanuj
      </C.PillButton>
      <C.Modal isOpen={isOpen} onClose={closeModal} title="Sprawdź kartę">
        <S.Root>
          {cardNumber ? (
            <C.CardDetails cardNumber={cardNumber} closeModal={closeModal} setError={setError} />
          ) : (
            <C.ScannerReader isActive={!cardNumber} onRead={onRead} />
          )}
        </S.Root>
        {cardNumber && error && <S.ErrorMessage error={error} />}
      </C.Modal>
    </>
  );
};
