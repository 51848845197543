import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';
import { useAuthContext } from 'context';

import * as M from './modals';
import * as H from './helpers';
import * as MH from './modals/helpers';

const columns = [
  {
    title: 'Nazwa karnetu',
    direction: null,
  },
  {
    title: 'Promocja',
    direction: null,
    width: '180px',
  },
  {
    title: 'Klient',
    direction: null,
    width: '220px',
  },
  {
    title: 'Status',
    direction: null,
    width: '120px',
  },
  {
    title: 'Cena',
    direction: null,
    width: '100px',
  },
  {
    title: 'Data zakupu',
    direction: 'DESC',
    width: '120px',
  },
  {
    title: '',
    width: '68px',
  },
];

export const Memberships = () => {
  const { me } = useAuthContext();
  const membershipStore = H.useMembershipState();

  const getTableRow = (item, openConfirmModal) =>
    H.getTableRow(item, me.role, membershipStore.openEditModal, membershipStore.openRemoveModal);

  const {
    isOpen,
    recordName,
    pageSWRs,
    closeConfirmModal,
    ...tableLayoutProps
  } = hooks.useTableLayoutData('memberships', columns, getTableRow, { isSearchExculded: true });

  const revalidate = () => pageSWRs.forEach(page => page.revalidate());

  const membership = membershipStore.membership || {};
  
  const removeMembershipAction = async () => {
    await MH.removeMembership(membership.slug);
    membershipStore.closeRemoveModal();
    revalidate();
  };

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
        Widok mobilny w przygotowaniu...
      </C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout
          isLoading={TH.checkIsTableLoading(pageSWRs)}
          title="Lista karnetów"
          columns={columns}
          {...tableLayoutProps}
        />
      </C.ResponsiveWrapper>
      <M.EditMembershipModal
        isOpen={membershipStore.isEditModalOpen}
        onClose={membershipStore.closeEditModal}
        membership={membership}
        revalidate={revalidate}
      />
      <C.ConfirmModal
        isOpen={membershipStore.isRemoveModalOpen}
        close={membershipStore.closeRemoveModal}
        confirmLabel="Usuń karnet"
        confirmAction={removeMembershipAction}
        cancelLabel="Anuluj"
      >
        Czy na pewno chcesz usunąć karnet "{membership.membershipTypeName}" klienta {membership.clientName}?
      </C.ConfirmModal>
    </C.Layout>
  );
};
