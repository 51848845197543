import React, { useEffect, useState, useRef } from 'react';

import * as S from './styled';
import { ReactComponent as CardSvg } from './card.svg';

export const ScannerReader = ({ isActive, onRead }) => {
  const inputRef = useRef(null);
  const [isMounted, setMounted] = useState(false);

  /* Trigger open-animation. */
  useEffect(() => {
    setTimeout(() => setMounted(true), 100);
  }, []);

  /* Keep the focus on scanner's input. */
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (inputRef && inputRef.current && isActive) {
        inputRef.current.focus();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isActive]);

  const onKeyUp = event => {
    if (event.keyCode === 13 && inputRef.current) {
      event.stopPropagation();
      event.preventDefault();

      onRead(inputRef.current.value);
      inputRef.current.value = '';
    }
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <S.Root>
      <S.Wrapper>
        <S.CardWrapper>
          <CardSvg />
          <S.Laser $isMounted={isActive && isMounted} />
        </S.CardWrapper>
        {isActive && (
          <S.TextWrapper>
            <S.Title $isMounted={isMounted}>Zeskanuj kartę</S.Title>
            <S.Subtitle $isMounted={isMounted}>Użyj skanera, aby zeskanować kartę</S.Subtitle>
          </S.TextWrapper>
        )}
        <S.InputWrapper>
          <input disabled={!isActive} ref={inputRef} onKeyUp={onKeyUp} onKeyDown={onKeyDown} />
        </S.InputWrapper>
      </S.Wrapper>
    </S.Root>
  );
};
