import React from 'react';
import { Button, KIND, SHAPE } from 'baseui/button';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';

import { ReactComponent as DotsSvg } from './dots.svg';

export const TableRowActionsButton = ({ items }) => (
  <StatefulPopover
    placement={PLACEMENT['leftTop']}
    overrides={{
      Body: { style: ({ $theme }) => ({ ...$theme.borderRadius.sm, overflow: 'hidden' }) },
      Inner: { style: ({ $theme }) => ({ ...$theme.borderRadius.sm, overflow: 'hidden' }) },
    }}
    content={({ close }) => (
      <StatefulMenu
        overrides={{
          List: { style: { outline: 'none' } },
          Option: {
            style: ({ $theme }) => ({
              ...$theme.typography.font160,
              color: $theme.colors.text100,
              ':hover': {
                color: $theme.colors.text,
                backgroundColor: 'transparent',
              },
            }),
          },
        }}
        onItemSelect={({ item }) => {
          close();

          if (typeof item.action === 'function') {
            item.action(item);
          }
        }}
        items={items}
      />
    )}
  >
    <Button
      shape={SHAPE.round}
      kind={KIND.tertiary}
      overrides={{
        BaseButton: {
          style: {
            paddingTop: '0.5rem',
            paddingRight: '0.5rem',
            paddingBottom: '0.5rem',
            paddingLeft: '0.5rem',
          },
        },
      }}
    >
      <DotsSvg width={19} height={19} />
    </Button>
  </StatefulPopover>
);
