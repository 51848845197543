import { useReducer } from 'react';

const initialState = { isFetching: false, options: [], error: null };

function reducer(state, action) {
  switch (action.type) {
    case 'fetch':
      return { ...state, isFetching: true, error: null };
    case 'success':
      return { ...state, isFetching: false, options: action.options };
    case 'error':
      return { ...state, isFetching: false, options: [], error: action.error };
    default:
      throw new Error();
  }
}

export const useSearchableSelectState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    isFetching: state.isFetching,
    options: state.options,
    error: state.error,
    startFetching: () => dispatch({ type: 'fetch' }),
    finishFetching: options => dispatch({ type: 'success', options }),
    receiveError: error => dispatch({ type: 'error', error }),
  };
};
