import React from 'react';
import { styled } from 'baseui';

import { ReactComponent as CloseIcon } from './close.svg';

const Button = styled('button', ({ $theme, $isOpen }) => ({
  backgroundColor: $theme.colors.border,
  borderRadius: '1rem',
  border: 'none',
  color: '#FFF',
  height: '1.875rem',
  width: '1.875rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 1,
  padding: 0,
  cursor: 'pointer',
  overflow: 'hidden',
  outline: 'none',
  transition: `${$theme.animation.timing100} background-color ${$theme.animation.easeOutCurve}`,

  ':hover': {
    backgroundColor: $theme.colors.background,
  },
}));

export const CloseButton = ({ onClick }) => (
  <Button type="button" onClick={onClick}>
    <CloseIcon />
  </Button>
);
