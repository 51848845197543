function scorePassword(password) {
  let score = 0;
  if (!password) return score;

  /* Award every unique letter until 5 repetitions. */
  const letters = {};
  for (let i = 0; i < password.length; i++) {
    letters[password[i]] = (letters[password[i]] || 0) + 1;
    score += 6.0 / letters[password[i]];
  }

  /* Bonus points for mixing it up. */
  const variations = {
    digits: /\d/.test(password),
    lower: /[a-z]/.test(password),
    upper: /[A-Z]/.test(password),
    nonWords: /\W/.test(password),
  };

  let variationCount = 0;
  for (let check in variations) {
    variationCount += variations[check] ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score);
}

export const checkPassStrength = password => {
  if (!password.length) {
    return { color: '#92929D', label: 'Brak', value: 0 };
  }

  if (password.length < 6) {
    return { color: '#EA4335', label: 'Za krótkie', value: 1 };
  }

  const score = scorePassword(password);
  if (score > 80) return { color: '#2DCA73', label: 'Mocne', value: 5 };
  if (score > 70) return { color: '#9BCA2C', label: 'Dobre', value: 4 };
  if (score > 60) return { color: '#B7CA2C', label: 'Dobre', value: 3 };
  if (score > 45) return { color: '#CA9A2C', label: 'Słabe', value: 2 };
  if (score >= 30) return { color: '#EA4335', label: 'Słabe', value: 1 };

  return '';
};
