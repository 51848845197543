import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';

import { httpClient } from 'utils';

import * as C from 'components';
import * as MC from 'components/Modal/components';
import * as F from 'modals/forms';

import * as S from './styled';
import * as H from '../../helpers';

const initialValues = { membershipTypeSlug: '', membershipOfferSlug: '', dateFrom: [new Date()] };
export class MembershipStep extends PureComponent {
  state = {
    isDone: false,
    isMounted: false,
    membershipItems: [],
    offersItems: [],
    error: '',
  };

  async componentDidMount() {
    setTimeout(() => this.setState({ isMounted: true }), 100);

    try {
      const {
        data: { items: membershipTypes },
      } = await httpClient('/membership-types');
      const filteredMT = membershipTypes.filter(mt => mt.isActive);
      const {
        data: { items: offers },
      } = await httpClient('/offers');
      const filteredOffers = offers.filter(offer => offer.isActive);

      const membershipItems = filteredMT.map(mt => ({ label: mt.name, value: mt.slug }));
      const offersItems = filteredOffers.map(o => ({
        label: o.name,
        value: o.slug,
        membershipTypeSlug: o.membershipTypeSlug,
      }));

      this.setState({ membershipItems, offersItems });
    } catch {
      this.setState({
        error:
          'Wystąpił błąd podczas pobierania listy dostępnych karnetów. Prosimy spróbować ponownie później.',
      });
    }
  }

  submitForm = async (values, { setErrors, setSubmitting }) => {
    this.setState({ error: '' });

    const result = await H.renewMembership(this.props.cardSlug, values);

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        this.props.nextStep();
        setTimeout(() => this.props.onClose(), 2000);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ error });
    setSubmitting(false);
  };

  render() {
    const { isDone, isMounted, error, membershipItems, offersItems } = this.state;
    const { onClose } = this.props;

    if (isDone) {
      return (
        <MC.StepWrapper $isMounted={isMounted}>
          <MC.CenteredContent>
            <MC.Done title="Karnet został zapisany" />
          </MC.CenteredContent>
        </MC.StepWrapper>
      );
    }

    return (
      <MC.StepWrapper $isMounted={isMounted}>
        <S.CardSuccess />
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitForm}
          render={({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <F.MembershipForm
                isSubmitting={isSubmitting}
                membershipItems={membershipItems}
                offersItems={offersItems}
                selectedMembershipType={values.membershipTypeSlug}
              />
              {error && <C.ErrorMessage error={error} />}
              <MC.Footer>
                <MC.Button type="button" disabled={isSubmitting} onClick={onClose}>
                  Pomiń
                </MC.Button>
                <MC.Button
                  $isConfirm
                  disabled={isSubmitting || !values.membershipTypeSlug || !values.dateFrom}
                  type="submit"
                >
                  Zapisz
                </MC.Button>
              </MC.Footer>
            </Form>
          )}
        />
      </MC.StepWrapper>
    );
  }
}
