import React from 'react';
import { Button as BUIButton, KIND, SHAPE } from 'baseui/button';

export const Button = ({ children, $isConfirm, ...props }) => (
  <BUIButton
    {...props}
    shape={SHAPE.pill}
    kind={$isConfirm ? KIND.primary : KIND.tertiary}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => ({
          ...$theme.typography.font260,
          backgroundColor: $isConfirm ? $theme.colors.primary : $theme.colors.background300,
          color: $isConfirm ? '#FFF' : $theme.colors.inactive,
          boxSizing: 'border-box',
          height: '2.5rem',
          paddingLeft: '1.875rem',
          paddingRight: '1.875rem',
          lineHeight: 1,
          pointerEvents: 'all',
        }),
      },
    }}
  >
    {children}
  </BUIButton>
);
