export const getRoutes = (role) => {
  switch (role) {
    case 'superadmin':
      return [{ label: 'Użytkownicy', to: '/panel/users' }];
    case 'owner':
      return [
        { label: 'Klienci', to: '/panel/clients' },
        { label: 'Karnety', to: '/panel/memberships' },
        { label: 'Promocje', to: '/panel/offers' },
        { label: 'Wizyty', to: '/panel/visits' },
        { label: 'Historia akcji', to: '/panel/logs' },
      ];
    case 'manager':
      return [];
    case 'employee':
      return [
        { label: 'Klienci', to: '/panel/clients' },
        { label: 'Karnety', to: '/panel/memberships' },
        { label: 'Promocje', to: '/panel/offers' },
        { label: 'Wizyty', to: '/panel/visits' },
      ];
    case 'client':
      return [
        { label: 'Moja karta', to: '/panel' },
        { label: 'Karnety', to: '/panel/memberships' },
        { label: 'Moje treningi', to: '/panel/trainings' },
      ];
    default:
      return [];
  }
};
