import React, { createContext, useState, useEffect, useContext } from 'react';

import { httpClient } from 'utils';

export const AuthContext = createContext(null);

const initialAuthData = { isLoading: true, me: null };

const AuthProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);

  const fetchMeDetails = async () => {
    try {
      const result = await httpClient.get('/auth/me');
      setAuthData({ isLoading: false, me: result.data });
    } catch {
      setAuthData({ isLoading: false, me: null });
    }
  };

  useEffect(() => {
    fetchMeDetails();
  }, []);

  return <AuthContext.Provider value={authData} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
