export const getColumns = (isEmployee) => {
  const columns = [
    {
      title: 'Nazwa promocji',
      direction: null,
    },
    {
      title: 'Zniżka',
      direction: null,
      width: '100px',
    },
    {
      title: 'Cena po obniżce',
      direction: null,
      width: '140px',
    },
    {
      title: 'Status',
      direction: null,
      width: '140px',
    },
    {
      title: 'Data utworzenia',
      direction: null,
      width: '160px',
    },
    {
      title: '',
      width: '68px',
    },
  ];

  if (isEmployee) {
    columns.pop();
  }

  return columns;
};
