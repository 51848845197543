import React, { createRef, useState } from 'react';
import { Input } from 'baseui/input';

import { withWidth } from 'hoc';

import { ReactComponent as Search } from './search.svg';

export const PillInput = withWidth(({ field, form, icon, width, $width, ...props }) => {
  const [isFocused, setFocus] = useState(false);
  const inputRef = createRef();
  const backgroundColor = props.backgroundColor || '#FFF';

  let startEnhancer;
  switch (icon) {
    case 'search':
      startEnhancer = () => <Search />;
      break;
    default:
      startEnhancer = undefined;
      break;
  }

  return (
    <Input
      {...field}
      {...props}
      onFocus={e => {
        setFocus(true);
        if (field && field.onFocus) field.onFocus(e);
      }}
      onBlur={e => {
        setFocus(false);
        if (field && field.onBlur) field.onBlur(e);
      }}
      inputRef={inputRef}
      startEnhancer={startEnhancer}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor,
            margin: 0,
            position: 'relative',
            width: $width,

            ':first-child': {
              marginTop: 0,
            },
          }),
        },
        InputContainer: {
          style: ({ $theme }) => ({
            backgroundColor,
            borderWidth: '1px',
            borderColor: isFocused ? $theme.colors.text100 : $theme.colors.border,
            ...$theme.borderRadius.sm,
            overflow: 'hidden',
            height: '2.5rem',
            borderTopLeftRadius: '1.25rem',
            borderTopRightRadius: '1.25rem',
            borderBottomLeftRadius: '1.25rem',
            borderBottomRightRadius: '1.25rem',
          }),
        },
        Input: {
          style: ({ $theme }) => ({
            ...$theme.typography.font200,
            backgroundColor,
            color: props.disabled ? $theme.colors.text100 : $theme.colors.text,
            paddingLeft: startEnhancer ? '3.125rem' : undefined,
            '::placeholder': {
              color: $theme.colors.text200,
            },
          }),
        },
        StartEnhancer: {
          style: ({ $theme }) => ({
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '3.125rem',
            boxSizing: 'border-box',
            backgroundColor: 'transparent',
          }),
        },
      }}
    />
  );
});
