import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuthContext } from 'context/AuthContext';

import { PageLoader } from './PageLoader';

export const PublicRoute = ({ component, ...props }) => {
  const { me, isLoading } = useAuthContext();

  if (isLoading) {
    return <PageLoader />;
  }

  return me ? <Redirect to={{ pathname: '/panel' }} /> : <Route {...props} component={component} />;
};
