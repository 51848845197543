import React from 'react';

import { ContentBox } from '../../ContentBox';

const marginStyle = { marginLeft: '-1rem', marginRight: '-1rem' };

export const TableRoot = ({ children, isInline, ...props }) =>
  isInline ? (
    <div style={marginStyle}>{children}</div>
  ) : (
    <ContentBox style={marginStyle}>{children}</ContentBox>
  );
