import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'baseui';

import { ReactComponent as LogoSvg } from './actevee.svg';
import { ReactComponent as LogoWhiteSvg } from './actevee-white.svg';

const Wrapper = styled('div', ({ $theme }) => ({
  textAlign: 'center',
  padding: '1.25rem 0',
}));

export const Logo = ({ color, ...props }) => (
  <Wrapper {...props}>
    <Link to="/">{color === 'white' ? <LogoWhiteSvg /> : <LogoSvg />}</Link>
  </Wrapper>
);
