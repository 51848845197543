import { useState } from 'react';
import { useSWRPages } from 'swr';

import * as TH from 'components/TableLayout/helpers';
import { swr } from 'utils';

import { useConfirmModal } from './useConfirmModal';
import { useDebounce } from './useDebounce';

export const useTableLayoutData = (name, columns, getTableRow, options = {}) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  /* Parse options object. */
  const isSearchExculded = options.isSearchExculded || false;
  const filters = options.filters || {};
  const alias = options.alias || name;

  /* Confirm modal handler. */
  const { isOpen, recordSlug, recordName, openConfirmModal, closeConfirmModal, additionalData } = useConfirmModal();

  const { pages, pageSWRs, isReachingEnd, isEmpty, loadMore } = useSWRPages(
    `${alias}-list-${debouncedSearch}`,
    ({ offset, withSWR }) => {
      const page = offset ? offset + 1 : 1;

      let url = `/${name}?perPage=50&page=${page}${getFiltersParams(filters)}`;
      if (!isSearchExculded) {
        url = `${url}&search=${debouncedSearch}`;
      }
      const swrResult = withSWR(swr(url));

      let data = [];
      if (Array.isArray(swrResult.data)) {
        data = swrResult.data.map((item) => getTableRow(item, openConfirmModal));
      } else if (swrResult.data && swrResult.data.items) {
        data = swrResult.data.items.map((item) => getTableRow(item, openConfirmModal));
      }
      

      return TH.transformDataIntoPages(columns, data);
    },
    TH.getTableOffset,
    [debouncedSearch],
  );

  const filteredPages = pages.filter((page, index) => {
    if (index > 0 && page.props.offset === null) {
      return false;
    }
    return true;
  });
  return {
    search: debouncedSearch,
    setSearch: !isSearchExculded ? setSearch : undefined,
    isOpen,
    isEmpty,
    recordSlug,
    recordName,
    closeConfirmModal,
    pages: filteredPages,
    pageSWRs,
    isReachingEnd,
    loadMore,
    confirmModalAdditionalData: additionalData,
  };
};

/**
 *    Helpers.
 */
const getFiltersParams = (filters) => {
  if (!filters || typeof filters !== 'object' || !Object.keys(filters).length) {
    return '';
  }

  return Object.keys(filters).reduce((acc, current) => `${acc}&${current}=${encodeURIComponent(filters[current])}`, '');
};
