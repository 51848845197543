import { httpClient, singleErrorMessageHandler } from 'utils';

export const fetchCardDetails = (cardNumber) =>
  httpClient(`/cards/${cardNumber}/status`)
    .then((res) => res.data)
    .catch((e) => console.log('e', e));

export const addCardToClient = (clientSlug, cardNumber) =>
  new Promise((resolve) => {
    httpClient
      .post(`/clients/${clientSlug}/cards/${cardNumber}`)
      .then(({ data: { slug } }) => resolve({ success: true, slug }))
      .catch((e) => {
        const errorResult = singleErrorMessageHandler(e);
        resolve(errorResult);
      });
  });

export const updateClientCard = (cardSlug, actionType) => {
  if (actionType === 'remove') {
    return httpClient.delete(`/cards/${cardSlug}/client`).catch((e) => console.log('e', e));
  }

  if (['active', 'inactive', 'blocked', 'lost'].includes(actionType)) {
    return httpClient.put(`/cards/${cardSlug}/status`, { status: actionType }).catch((e) => console.log('e', e));
  }

  return Promise.resolve();
};
