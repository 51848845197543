import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

import { styled, withStyle } from 'baseui';
import { ModalBody } from 'baseui/modal';

import animationData from 'animations/done.json';

export const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2.375rem',
  paddingTop: '1.875rem',
  paddingLeft: '1.875rem',
  paddingRight: '1.875rem',
});

export const Title = styled('h2', ({ $theme }) => ({
  ...$theme.typography.font560,
  margin: 0,
  lineHeight: 1,
  color: $theme.colors.text,
}));

export const Body = withStyle(ModalBody, ({ $width }) => ({
  width: $width || '31.25rem',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  textAlign: 'center',
  marginTop: 0,
  marginBottom: 0,
  marginRight: 0,
  marginLeft: 0,
  padding: '1.875rem',
}));

export const StepWrapper = styled('div', ({ $theme, $isMounted }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  flexGrow: 1,
  opacity: $isMounted ? 1 : 0,
  transition: `${$theme.animation.timing100} opacity ${$theme.animation.easeOutCurve}`,
}));

export const CenteredContent = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
});

/**
 *    Done animation component.
 */
const DoneWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '20rem',
  width: '20rem',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const DoneTitle = styled('h3', ({ $theme, $isMounted }) => ({
  width: '100%',
  textAlign: 'center',
  ...$theme.typography.font400,
  color: $theme.colors.text,
  marginTop: '-8rem',
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  opacity: $isMounted ? 1 : 0,
  transition: `${$theme.animation.timing100} opacity ${$theme.animation.easeOutCurve} 500ms`,
}));

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const Done = ({ title }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  /* Trigger open-animation. */
  useEffect(() => {
    setTimeout(() => setIsMounted(true), 100);
  }, []);

  const eventListeners = [
    {
      eventName: 'enterFrame',
      callback: frameEvent => {
        if (frameEvent.currentTime >= 21) {
          setIsPaused(true);
        }
      },
    },
  ];

  return (
    <DoneWrapper>
      <Lottie
        options={defaultOptions}
        eventListeners={eventListeners}
        height={320}
        width={320}
        isPaused={isPaused}
        isClickToPauseDisabled
      />
      {title && <DoneTitle $isMounted={isMounted}>{title}</DoneTitle>}
    </DoneWrapper>
  );
};
