export const singleErrorMessageHandler = e => {
  let result = { error: null };
  let data = {};
  if (e.response && e.response.data) {
    ({ data } = e.response);
  }

  if (data && Array.isArray(data.message) && data.message.length) {
    const error = data.message[0];
    const constraints = error.constraints || {};
    if (error && Object.keys(constraints).length) {
      result.error = constraints[Object.keys(constraints)[0]];
    }
  }

  if (data && typeof data.message === 'string') {
    result.error = data.message;
  }

  if (!result.error) {
    result.error = 'Nie udało się zapisać przesłanych danych.';
  }

  return result;
};
