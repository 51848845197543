import React from 'react';
import { Field } from 'formik';

import * as C from 'components';
import * as FS from '../styled';

const inputRootOverrides = {
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
};

export const MembershipForm = ({
  isSubmitting,
  selectedMembershipType,
  membershipItems,
  offersItems,
}) => {
  const filteredOffersItems = offersItems.filter(
    o => o.membershipTypeSlug === selectedMembershipType,
  );
  const isOffersDisabled = !filteredOffersItems.length;

  return (
    <>
      <FS.Row>
        <Field
          name="membershipTypeSlug"
          placeholder="Rodzaj karnetu"
          disabled={isSubmitting}
          component={C.Dropdown}
          RootOverrides={inputRootOverrides}
          items={membershipItems}
        />
      </FS.Row>
      <FS.Row>
        <Field
          name="membershipOfferSlug"
          placeholder="Wybierz promocję"
          disabled={isSubmitting || isOffersDisabled}
          component={C.Dropdown}
          RootOverrides={inputRootOverrides}
          items={filteredOffersItems}
        />
      </FS.Row>
      <FS.Row $marginBottom={0}>
        <Field
          name="dateFrom"
          placeholder="Karnet od"
          disabled={isSubmitting}
          component={C.Datepicker}
          RootOverrides={inputRootOverrides}
        />
      </FS.Row>
    </>
  );
};
