import React from 'react';
import { styled } from 'baseui';

import { useAuthContext } from 'context/AuthContext';

import * as C from 'components';

const ThreeColumns = styled('div', { display: 'flex' });
const Column = styled('div', {
  flexGrow: 1,
  maxWidth: '375px',
  width: '30%',
  flexBasis: '30%',
  paddingLeft: '1rem',

  ':first-child': {
    paddingLeft: 0,
  },
});

export const Dashboard = () => {
  const { me } = useAuthContext();
  if (me && ['owner', 'employee'].includes(me.role)) {
    window.location = '/panel/clients'; // TODO: Refactor
  }

  const companyName = 'ozofit';
  const clientName = 'Artur Hamela';
  const expireDate = '06.01.2020';

  const lastVisitDate = '05.12.2019';
  const lastVisitItems = [
    { label: 'Wejście', value: '11:24' },
    { label: 'Wyjście', value: '12:43' },
    { label: 'Czas', value: '1:19 godz.' },
  ];

  const cardDetailsItems = [
    { label: 'Typ karnetu', value: 'Fitness 10 wejść' },
    { label: 'Karnet wygasa', value: expireDate },
    { label: 'Pozostałe wejścia', value: '6' },
  ];

  return (
    <C.Layout title="Twój karnet">
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
        <C.MembershipCard
          companyName={companyName}
          clientName={clientName}
          expireDate={expireDate}
        />
        <C.DetailsCard
          title="Ostatnia wizyta"
          direction="row"
          subtitle={lastVisitDate}
          items={lastVisitItems}
          icon="green-calendar-round"
        />
        <C.DetailsCard
          title="Szczegóły"
          direction="column"
          items={cardDetailsItems}
          icon="green-dash"
        />
      </C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <ThreeColumns>
          <Column>
            <C.MembershipCard
              companyName={companyName}
              clientName={clientName}
              expireDate={expireDate}
            />
          </Column>
          <Column>
            <C.DetailsCard
              title="Szczegóły"
              direction="column"
              items={cardDetailsItems}
              icon="green-dash"
            />
          </Column>
          <Column>
            <C.DetailsCard
              title="Ostatnia wizyta"
              direction="row"
              subtitle={lastVisitDate}
              items={lastVisitItems}
              icon="green-calendar-round"
            />
          </Column>
        </ThreeColumns>
      </C.ResponsiveWrapper>
    </C.Layout>
  );
};
