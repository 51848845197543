import React from 'react';
import { styled } from 'baseui';

import { withWidth } from 'hoc';

export const Consent = withWidth(
  styled('p', ({ $theme, width }) => ({
    ...$theme.typography.font200,
    fontFamily: $theme.primaryFontFamily,
    color: $theme.colors.text100,
    textAlign: 'center',
    margin: '1.25rem auto',
  })),
);

const StyledConsentLink = styled('a', ({ $theme }) => ({
  ...$theme.typography.font200,
  fontFamily: $theme.primaryFontFamily,
  color: $theme.colors.link,
  textDecoration: 'none',
}));

export const ConsentLink = ({ children, href }) => (
  <StyledConsentLink href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </StyledConsentLink>
);
