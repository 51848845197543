import React from 'react';
import { withRouter } from 'react-router-dom';
import { styled } from 'baseui';

import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { ReactComponent as ArrowDown } from './arrow-down.svg';

const StyledArrowDown = styled(ArrowDown, { marginLeft: '0.25rem' });

const Wrapper = styled('div', ({ $theme }) => ({
  ...$theme.typography.font200,
  fontFamily: $theme.primaryFontFamily,
  color: $theme.colors.text,
  cursor: 'pointer',
}));

const Avatar = styled('span', ({ $theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: ' center',
  width: '2rem',
  height: '2rem',
  fontSize: '1rem',
  fontWeight: 600,
  fontFamily: $theme.primaryFontFamily,
  color: $theme.colors.primary,
  backgroundColor: '#ebfaf2',
  margin: '0 0.25rem',
  borderRadius: '50%',
}));

const logoutAction = () => {
  try {
    localStorage.removeItem('accessToken');
  } catch {
    // Nothing to do
  }

  window.location = '/login';
};

const getMenuItems = (role) => {
  switch (role) {
    case 'owner':
      return [
        { label: 'Rodzaje karnetów', to: '/panel/membership-types' },
        { label: 'Pracownicy', to: '/panel/employees' },
        { label: 'Wyloguj', action: logoutAction },
      ];
    case 'employee':
      return [
        { label: 'Rodzaje karnetów', to: '/panel/membership-types' },
        { label: 'Wyloguj', action: logoutAction },
      ];
    default:
      return [{ label: 'Wyloguj', action: logoutAction }];
  }
};

export const Dropdown = withRouter(({ name, role, history }) => (
  <StatefulPopover
    placement={PLACEMENT['bottomRight']}
    overrides={{
      Body: { style: ({ $theme }) => ({ ...$theme.borderRadius.sm, overflow: 'hidden' }) },
      Inner: { style: ({ $theme }) => ({ ...$theme.borderRadius.sm, overflow: 'hidden' }) },
    }}
    content={({ close }) => (
      <StatefulMenu
        overrides={{
          List: { style: { outline: 'none' } },
          Option: {
            style: ({ $theme }) => ({
              ...$theme.typography.font160,
              color: $theme.colors.text100,
              ':hover': {
                color: $theme.colors.text,
                backgroundColor: 'transparent',
              },
            }),
          },
        }}
        onItemSelect={({ item }) => {
          close();

          if (typeof item.action === 'function') {
            item.action();
          }

          if (typeof item.to === 'string') {
            history.push(item.to);
          }
        }}
        items={getMenuItems(role)}
      />
    )}
  >
    <Wrapper>
      {name} <Avatar>{name.substr(0, 1)}</Avatar>
      <StyledArrowDown />
    </Wrapper>
  </StatefulPopover>
));
