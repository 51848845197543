import React from 'react';

import * as hooks from 'hooks';
import * as C from 'components';
import * as TH from 'components/TableLayout/helpers';

import * as H from './helpers';

const columns = [
  {
    title: 'Klient',
    direction: null,
  },
  {
    title: 'Data wejścia',
    direction: 'DESC',
    width: '160px',
  },
  {
    title: 'Wyjście',
    direction: null,
    width: '140px',
  },
  {
    title: 'Czas spędzony',
    direction: null,
    width: '140px',
  },
  {
    title: 'Status',
    direction: null,
    width: '140px',
  },
];

export const Visits = () => {
  const {
    isOpen,
    recordSlug,
    recordName,
    pageSWRs,
    closeConfirmModal,
    ...tableLayoutProps
  } = hooks.useTableLayoutData('visits', columns, H.getTableRow, { isSearchExculded: true });

  const revalidate = () => pageSWRs.forEach(page => page.revalidate());

  /* Make sure visits list refreshes every 1 minute. */
  hooks.useInterval(revalidate, 60000);

  const confirmModalAction = async () => {
    await H.finishVisitAction(recordSlug);
    revalidate();
    closeConfirmModal();
  };

  return (
    <C.Layout>
      <C.ResponsiveWrapper size={['xs', 'sm', 'md']}>
        Widok mobilny w przygotowaniu...
      </C.ResponsiveWrapper>
      <C.ResponsiveWrapper size="lg">
        <C.TableLayout
          isLoading={TH.checkIsTableLoading(pageSWRs)}
          title="Wizyty"
          columns={columns}
          {...tableLayoutProps}
        />
      </C.ResponsiveWrapper>
      <C.ConfirmModal
        isOpen={isOpen}
        close={closeConfirmModal}
        confirmLabel="Zakończ wizytę"
        confirmAction={confirmModalAction}
        cancelLabel="Anuluj"
      >
        Czy na pewno chcesz zakończyć wizytę klienta:{' '}
        <C.ModalRecordName>{recordName}</C.ModalRecordName>?
      </C.ConfirmModal>
    </C.Layout>
  );
};
