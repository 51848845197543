import { styled, withStyle } from 'baseui';
import { StyledTable, StyledHead, StyledBody, StyledRow, StyledCell } from 'baseui/table';

export const Header = styled('div', {});

export const HeaderRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.875rem',
});

export const Title = styled('h1', ({ $theme }) => ({
  ...$theme.typography.font560,
  color: $theme.colors.text,
  margin: 0,
  paddingLeft: '1rem',
}));

export const SearchWrapper = styled('div', { justifySelf: 'flex-end' });

export const Table = withStyle(StyledTable, {
  borderWidth: 0,
  overflowX: 'auto',
});

export const Head = withStyle(StyledHead, ({ $theme }) => ({
  boxShadow: 'none',
  borderBottomStyle: 'solid',
  borderBottomColor: $theme.colors.border,
  borderBottomWidth: '1px',
}));

export const Body = withStyle(StyledBody, ({ $theme, $isLoading }) => ({
  position: 'relative',
  minHeight: $isLoading ? '16rem' : '1rem',
  transition: `${$theme.animation.timing100} min-height ${$theme.animation.easeOutCurve}`,
}));

export const Row = withStyle(StyledRow, ({ $isFaded }) => ({
  paddingTop: '1.5rem',
  opacity: $isFaded ? '0.4' : '1',
}));

export const Cell = withStyle(StyledCell, ({ $width }) => ({
  width: $width,
  flex: $width ? `0 ${$width}` : '1',
  textAlign: 'left',
  boxSizing: 'border-box',
  paddingTop: '0',
  paddingRight: '1rem',
  paddingBottom: '0',
  paddingLeft: '1rem',
}));
