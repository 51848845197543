import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';
import { isValid } from 'date-fns';

import * as C from 'components';
import * as F from 'modals/forms';
import * as MC from 'components/Modal/components';

import * as H from '../../helpers';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  birthdate: null,
  notes: '',
};

export class ClientStep extends PureComponent {
  state = { isDone: false, isMounted: true, error: '' };

  submitForm = async (values, { setErrors, setSubmitting }) => {
    const birthdate = H.getValidBirthdate(values.birthdate);
    if (values.birthdate && !birthdate) {
      this.setState({ error: 'Wprowadzono niepoprawną datę urodzenia.'});
      return setSubmitting(false);
    }
    this.setState({ error: '' });

    const result = await H.createClient({ ...values, birthdate });

    if (result && result.success) {
      this.setState({ isDone: true, error: '' }, () => {
        setTimeout(() => this.setState({ isMounted: false }), 2000);
        setTimeout(() => this.props.nextStep(result.slug), 2500);
      });

      return;
    }

    const error = result.error || 'Coś poszło nie tak. Prosimy spróbować ponownie później.';
    this.setState({ error });
    setSubmitting(false);
  };

  render() {
    const { isDone, isMounted, error } = this.state;

    if (isDone) {
      return (
        <MC.StepWrapper $isMounted={isMounted}>
          <MC.CenteredContent>
            <MC.Done title="Klient został dodany" />
          </MC.CenteredContent>
        </MC.StepWrapper>
      );
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <F.ClientForm isSubmitting={isSubmitting} />
            {error && <C.ErrorMessage error={error} />}
            <MC.Footer>
              <div />
              <MC.Button
                $isConfirm
                disabled={isSubmitting || !values.firstName || !values.lastName}
                type="submit"
              >
                Dalej
              </MC.Button>
            </MC.Footer>
          </Form>
        )}
      />
    );
  }
}
